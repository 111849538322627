import { ArrowPathIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { CheckIcon } from "@heroicons/react/24/solid";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { ChartPieIcon } from "@heroicons/react/24/solid";
import { ArrowLeftEndOnRectangleIcon } from "@heroicons/react/24/solid";
import React from "react";
import useGetLevel from "../../hooks/useGetLevel";
import useApprove from "../../hooks/useApprove";

function ApprovalDetail({ data }) {
  const { levels } = useGetLevel();
  const {
    levelCode,
    setLevelCode,
    markup,
    setMarkup,
    submitApproval,
    loading,
  } = useApprove();

  const renderLevel = () => {
    return levels.map((level) => {
      return (
        <option key={level.kode} value={level.kode}>
          {level.kode} - {level.nama}
        </option>
      );
    });
  };
  return (
    <dialog id="my_modal_2" className="modal">
      <div className="modal-box bg-white max-w-4xl">
        {data ? (
          <>
            <div className="flex items-center justify-between">
              <h3 className="font-bold text-lg text-primaryColor">
                Approval - {data.attributes.name}
              </h3>
            </div>

            <div className="mt-4 gap-y-4 flex flex-col text-gray-600">
              <div className="flex flex-col p-4 bg-gray-100 rounded-lg m-2 gap-y-4">
                <div className="flex gap-x-4 items-start">
                  <div className="w-1/2">
                    <label className="flex text-sm font-semibold leading-6 text-gray-900">
                      Level
                      <p className="ml-2 text-gray-400 italic">
                        (Set Reseller Level)
                      </p>
                    </label>
                    <div className="mt-2.5">
                      <select
                        onChange={(e) => setLevelCode(e.target.value)}
                        className="select w-full max-w-xs bg-gray-300 select-sm"
                      >
                        <option disabled selected>
                          Pilih Level
                        </option>
                        {renderLevel()}
                      </select>
                    </div>
                  </div>

                  <div className="w-1/2">
                    <label className="flex text-sm font-semibold leading-6 text-gray-900">
                      Markup
                      <p className="ml-2 text-gray-400 italic">
                        (Set harga markup)
                      </p>
                    </label>
                    <div className="mt-2.5">
                      <input
                        type="number"
                        value={markup}
                        onChange={(e) => setMarkup(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        placeholder="Markup Reseller"
                        className="block w-full rounded-md bg-gray-100 border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex gap-x-4 items-start">
                  <div className="w-1/2 mt-auto mx-auto">
                    {loading ? (
                      <div className="flex mx-auto w-fit gap-x-4  p-4 bg-gray-300 rounded-lg ">
                        <ArrowPathIcon className="w-4 animate-spin" />
                      </div>
                    ) : (
                      <div className="flex items-center w-full gap-x-4 p-2">
                        <button
                          disabled={loading}
                          onClick={(e) =>
                            submitApproval(data.attributes.id, true)
                          }
                          className="btn btn-md w-1/2 border-0 bg-emerald-500 hover:bg-emerald-600 text-white"
                        >
                          <CheckIcon className="w-6" />
                          Approve
                        </button>
                        <button
                          disabled={loading}
                          onClick={(e) =>
                            submitApproval(data.attributes.id, false)
                          }
                          className="btn btn-md w-1/2 border-0 bg-rose-500 hover:bg-rose-600 text-white"
                        >
                          <XMarkIcon className="w-6" />
                          Reject
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-x-4 w-full my-4">
                <p className="text-primaryColor">Informasi Akun</p>
                <div className="flex-grow h-1 bg-primaryColor rounded-full"></div>
              </div>

              <div className="flex gap-x-4 items-start">
                <div className="w-1/2">
                  <label class="flex text-sm font-semibold leading-6 text-gray-900">
                    Email
                    <p className="ml-2 text-gray-400 italic">
                      (Pastikan email aktif)
                    </p>
                  </label>
                  <div class="mt-2.5">
                    <p className="bg-gray-100 rounded-lg px-4 py-2">
                      {data.attributes.email}
                    </p>
                  </div>
                </div>

                <div className="w-1/2">
                  <label class="flex text-sm font-semibold leading-6 text-gray-900">
                    Owner Name
                    <p className="ml-2 text-gray-400 italic">(Nama Owner)</p>
                  </label>
                  <div class="mt-2.5">
                    <p className="bg-gray-100 rounded-lg px-4 py-2">
                      {data.attributes.owner_name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <label class="flex text-sm font-semibold leading-6 text-gray-900">
                  Address
                  <p className="ml-2 text-gray-400 italic">(Alamat Kantor)</p>
                </label>
                <div class="mt-2.5">
                  <p className="bg-gray-100 rounded-lg px-4 py-2">
                    {data.attributes.address}
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-x-4 w-full my-4">
                <p className="text-primaryColor">Informasi Umum</p>
                <div className="flex-grow h-1 bg-primaryColor rounded-full"></div>
              </div>
              <div className="flex gap-x-4 items-start">
                <div className="w-1/3 h-full  flex flex-col justify-between ">
                  <label class="flex text-sm font-semibold leading-6 text-gray-900">
                    ID/KTP
                    <p className="ml-2 text-gray-400 italic">
                      (Nomor Identitas)
                    </p>
                  </label>
                  <div class="mt-2.5">
                    <p className="bg-gray-100 rounded-lg px-4 py-2">
                      {data.attributes.ktp}
                    </p>
                  </div>
                </div>

                <div className="w-1/3">
                  <label class="flex text-sm font-semibold leading-6 text-gray-900">
                    NPWP
                    <p className="ml-2 text-gray-400 italic">
                      (Bagi Pengusaha Kena Pajak)
                    </p>
                  </label>
                  <div class="mt-2.5">
                    <p className="bg-gray-100 rounded-lg px-4 py-2">
                      {data.attributes.npwp}
                    </p>
                  </div>
                </div>

                <div className="w-1/3">
                  <label class="flex text-sm font-semibold leading-6 text-gray-900">
                    Phone Number
                    <p className="ml-2 text-gray-400 italic">
                      (Transaksi via SMS)
                    </p>
                  </label>
                  <div class="mt-2.5">
                    <p className="bg-gray-100 rounded-lg px-4 py-2">
                      {data.attributes.phone_number}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex gap-x-4 items-start">
                <div className="w-1/2">
                  <label class="flex text-sm font-semibold leading-6 text-gray-900">
                    OtomaX ID
                    <p className="ml-2 text-gray-400 italic">(ID OtomaX)</p>
                  </label>
                  <div class="mt-2.5">
                    <p className="bg-gray-100 rounded-lg px-4 py-2">
                      {data.attributes.oid}
                    </p>
                  </div>
                </div>

                <div className="w-1/2">
                  <label class="flex text-sm font-semibold leading-6 text-gray-900">
                    Jabber Id
                    <p className="ml-2 text-gray-400 italic">
                      (Transaksi via Jabber)
                    </p>
                  </label>
                  <div class="mt-2.5">
                    <p className="bg-gray-100 rounded-lg px-4 py-2">
                      {data.attributes.jabber_id}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex gap-x-4 items-start">
                <div className="w-1/2">
                  <label class="flex text-sm font-semibold leading-6 text-gray-900">
                    WA Number
                    <p className="ml-2 text-gray-400 italic">
                      (Transaksi via Whatsapp)
                    </p>
                  </label>
                  <div class="mt-2.5">
                    <p className="bg-gray-100 rounded-lg px-4 py-2">
                      {data.attributes.wa_number}
                    </p>
                  </div>
                </div>

                <div className="w-1/2">
                  <label class="flex text-sm font-semibold leading-6 text-gray-900">
                    URL Report
                    <p className="ml-2 text-gray-400 italic">(URL Report)</p>
                  </label>
                  <div class="mt-2.5">
                    <p className="bg-gray-100 rounded-lg px-4 py-2">
                      {data.attributes.url_report}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <form method="dialog" className="modal-backdrop">
        <button
          onClick={(e) => {
            setMarkup(0);
          }}
        >
          close
        </button>
      </form>
    </dialog>
  );
}

export default ApprovalDetail;
