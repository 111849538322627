import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { formatNumber } from "../../../Utils";
import { format } from "date-fns";
export default function TopSupplier({ suppliers }) {
  const [totalAmount, setTotalAmount] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const temp = suppliers.slice(0, 5);
    setTotalAmount(temp.map((supplier) => supplier.count));
    setData(
      temp.map((supplier) => {
        return {
          x: supplier.name,
          y: supplier.amount,
        };
      })
    );
  }, [suppliers]);

  const options = {
    chart: {
      type: "bar",
      id: "basic-bar",
      width: "100%",
    },
    colors: ["#2c3364"],
    fill: { opacity: 1 },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        borderRadiusApplication: "around",
        borderRadiusWhenStacked: "end",
        columnWidth: "70%",
        barHeight: "70%",
        distributed: false,
        rangeBarOverlap: true,
        rangeBarGroupRows: false,
        hideZeroBarsWhenGrouped: false,
        isDumbbell: false,
        dumbbellColors: undefined,
        isFunnel: false,
        isFunnel3d: true,

        dataLabels: {
          position: "top",
          maxItems: 100,
          hideOverflowingLabels: true,
          orientation: "horizontal",
          total: {
            enabled: false,
            formatter: undefined,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#373d3f",
              fontSize: "12px",
              fontFamily: undefined,
              fontWeight: 600,
            },
          },
        },
      },
    },
    series: [
      {
        name: "Total Transaction",
        data: formatNumber(data.map((sup) => sup.y)),
      },
    ],
    xaxis: {
      categories: data.map((sup) => sup.x),
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: undefined,
      formatter: function (val, opts) {
        return `${formatNumber(val)}`;
      },
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: -30,
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: undefined,
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 4,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
      labels: ["Apples", "Oranges", "Berries", "Grapes"],
      annotations: {
        xaxis: [
          {
            x: 0,
            x2: null,
            strokeDashArray: 1,
            borderColor: "#c2c2c2",
            fillColor: "#c2c2c2",
            opacity: 0.3,
            offsetX: 0,
            offsetY: 0,
            label: {
              borderColor: "#c2c2c2",
              borderWidth: 1,
              borderRadius: 2,
              text: undefined,
              textAnchor: "middle",
              position: "top",
              orientation: "vertical",
              offsetX: 0,
              offsetY: 0,
              mouseEnter: undefined,
              mouseLeave: undefined,
              click: undefined,
              style: {
                background: "#fff",
                color: "#777",
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: undefined,
                cssClass: "apexcharts-xaxis-annotation-label",
              },
            },
          },
        ],
      },
    },
  };

  return (
    <Chart
      options={options}
      series={options.series}
      type="bar"
      width="100%"
      height="350"
    />
  );
}
