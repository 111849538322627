import { useState, React } from "react";
import DashboardSidebar from "./components/DashboardSidebar";
import { useUser } from "../../context/UserContext";
import { numberFormatWithoutCurr } from "../../Utils";
import {
  CurrencyDollarIcon,
  CursorArrowRaysIcon,
  ExclamationCircleIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import useGetDashboard from "../../hooks/useGetDashboard";
import TodayRevenue from "./components/TodayRevenue";
import { PiCoinsBold, PiBankBold } from "react-icons/pi";
import { HiArrowTrendingDown, HiArrowTrendingUp } from "react-icons/hi2";
import { ChartPieIcon } from "@heroicons/react/16/solid";
import DashboardTransaction from "./components/DashboardTransaction";
import TopProducts from "./components/TopProducts";
import { BuildingStorefrontIcon } from "@heroicons/react/16/solid";
import TopReseller from "./components/TopReseller";
import useReduxDashboardToday from "../../hooks/useReduxDashboardToday";
import TopSupplier from "./components/TopSupplier";
import DashboardTab from "./components/DashboardTab";
import TotalMargin from "./components/TotalMargin";
import { DashboardDrawer } from "./components/DashboardDrawer";

export default function DashboardToday() {
  const { user, balance } = useUser();

  const isAllowed = (allowedRoles) => {
    return allowedRoles.includes(user?.role_id);
  };
  const {
    loading,
    data,
    refresh,
    setRefresh,
    month,
    setMonth,
    year,
    setYear,
    revenueToday,
    comparisonRevenueToday,
    lastMonthComparison,
    marginByDate,
    comparisonMargin,
  } = useGetDashboard();

  const { productsToday, resellersToday, suppliersToday } =
    useReduxDashboardToday();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => setIsDrawerOpen(true);

  const closeDrawer = () => setIsDrawerOpen(false);

  return (
    <div className=" flex-grow font-poppins bg-gray-50">
      <div className="bg-transparent flex w-full h-full">
        <DashboardSidebar />
        <DashboardDrawer
          isDrawerOpen={isDrawerOpen}
          closeDrawer={closeDrawer}
          openDrawer={openDrawer}
        />

        <div className="flex flex-col flex-grow gap-y-4 max-w-7xl mx-auto p-6 overflow-y-auto">
          <DashboardTab
            isDrawerOpen={isDrawerOpen}
            openDrawer={openDrawer}
            closeDrawer={closeDrawer}
          />
          <p className="text-xl text-gray-800 font-semibold">Insight</p>
          <div className="flex flex-col lg:flex-row w-full gap-4 ">
            <div className="flex items-center w-full lg:w-[450px] bg-primaryColor rounded-xl text-white  p-4  justify-between">
              <div>
                <p className="text-sm">Saldo Anda</p>
                <p className="text-2xl font-bold">
                  Rp {numberFormatWithoutCurr(balance)}
                </p>
              </div>

              <a
                href="/deposit_request"
                className="bg-white flex items-center text-primaryColor text-md p-4 py-1 rounded-full w-fit h-fit"
              >
                <CurrencyDollarIcon className="w-4 mr-2" />
                Request Deposit
              </a>
            </div>
            <div className="flex flex-col lg:flex-row items-start lg:items-center flex-grow bg-primaryColor rounded-xl text-white p-4 lg:justify-between">
              <h1 className="">
                Today's <br /> Transaction Status
              </h1>
              <div className="hidden text-white items-center lg:flex pl-4 pr-8 py-1.5 rounded-lg">
                <CursorArrowRaysIcon className="w-6 mr-3" />
                <div className="flex flex-col">
                  <p className=" text-bottom text-sm font-semibold">Success</p>
                  <p className=" text-bottom text-lg font-semibold">
                    {numberFormatWithoutCurr(data.success_count)}
                  </p>
                </div>
              </div>
              <div className="hidden text-white items-center lg:flex pl-4 pr-8 py-1.5 rounded-lg">
                <ExclamationCircleIcon className="w-6 mr-3" />
                <div className="flex flex-col">
                  <p className=" text-bottom text-sm font-semibold">Failed</p>
                  <p className=" text-bottom text-lg font-semibold">
                    {numberFormatWithoutCurr(data.failed_count)}
                  </p>
                </div>
              </div>
              <div className="hidden text-white items-center lg:flex pl-4 pr-8 py-1.5 rounded-lg">
                <ClockIcon className="w-6 mr-3" />
                <div className="flex flex-col">
                  <p className=" text-bottom text-sm font-semibold">Pending</p>
                  <p className=" text-bottom text-lg font-semibold">
                    {data.pending_count}
                  </p>
                </div>
              </div>
              <div className="lg:hidden flex flex-row">
                <div className="text-white items-center flex pl-4 pr-4 py-1.5 rounded-lg">
                  <CursorArrowRaysIcon className="w-6 mr-2" />
                  <div className="flex flex-col">
                    <p className=" text-bottom text-sm font-semibold">
                      Success
                    </p>
                    <p className=" text-bottom text-lg font-semibold">
                      {numberFormatWithoutCurr(data.success_count)}
                    </p>
                  </div>
                </div>
                <div className="text-white items-center flex pl-4 pr-4 py-1.5 rounded-lg">
                  <ExclamationCircleIcon className="w-6 mr-2" />
                  <div className="flex flex-col">
                    <p className=" text-bottom text-sm font-semibold">Failed</p>
                    <p className=" text-bottom text-lg font-semibold">
                      {numberFormatWithoutCurr(data.failed_count)}
                    </p>
                  </div>
                </div>
                <div className="text-white items-center flex pl-4 pr-4 py-1.5 rounded-lg">
                  <ClockIcon className="w-6 mr-2" />
                  <div className="flex flex-col">
                    <p className=" text-bottom text-sm font-semibold">
                      Pending
                    </p>
                    <p className=" text-bottom text-lg font-semibold">
                      {data.pending_count}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col lg:flex-row w-full gap-x-4 justify-between`}
          >
            <div
              className={`bg-gray-100 text-gray-700  flex flex-col rounded-xl pl-4 pr-4 pt-4 overflow-hidden w-full lg:w-1/3 h-full`}
            >
              <div className="flex items-center gap-x-2">
                <PiCoinsBold
                  size={25}
                  className="text-primaryColor rounded-full p-1.5 bg-primaryColor/10"
                />
                <h3 className="text-sm font-semibold"> Today Transaction</h3>
              </div>
              <div className="flex items-center text-[10px] mt-1">
                {comparisonRevenueToday < 0 ? (
                  <div className="flex items-center py-1 px-2 bg-rose-100 w-fit rounded-full mr-1 text-rose-600">
                    <HiArrowTrendingDown size={12} className="mr-1" />
                    {comparisonRevenueToday}%
                  </div>
                ) : (
                  <div className="flex items-center py-1 px-2 bg-emerald-100 w-fit rounded-full mr-1 text-emerald-600">
                    <HiArrowTrendingUp size={12} className="mr-1" />
                    {comparisonRevenueToday}%
                  </div>
                )}

                <p>vs yesterday</p>
              </div>
              <TodayRevenue revenue={revenueToday} loading={loading} />
            </div>

            {isAllowed([2]) && (
              <div className="bg-gray-100 text-gray-700 w-full flex flex-col rounded-xl pl-4 pr-4 pt-4">
                <div className="flex items-center gap-x-2">
                  <ChartPieIcon className="w-6" />
                  <p className=" ">Top Products</p>
                </div>
                <div className="flex gap-x-4 text-gray-700 items-center pt-4">
                  <TopProducts data={productsToday} />
                </div>
              </div>
            )}

            {isAllowed([1]) && (
              <div className="bg-gray-100 text-gray-700 flex flex-col flex-auto rounded-xl pl-4 pr-4 pt-4 w-full lg:w-2/3 ">
                <div className="flex items-center gap-x-2">
                  <PiBankBold
                    size={25}
                    className="text-primaryColor rounded-full p-1.5 bg-primaryColor/10"
                  />
                  <h3 className="text-sm font-semibold"> Top Suppliers</h3>
                </div>
                <TopSupplier suppliers={suppliersToday} />
              </div>
            )}
          </div>
          <div className="flex lg:flex-row flex-col gap-y-4 lg:gap-x-4">
            {user?.role_id === 1 && (
              <div className="bg-gray-100 text-gray-700 w-full lg:w-1/2 flex flex-col rounded-xl pt-4 pl-4">
                <div className="flex items-center gap-x-2 ">
                  <PiCoinsBold
                    size={25}
                    className="text-primaryColor rounded-full p-1.5 bg-primaryColor/10"
                  />
                  <h3 className="text-sm font-semibold"> Total Margin</h3>
                </div>
                <h1 className="text-2xl mt-1 font-bold">
                  Rp. {numberFormatWithoutCurr(marginByDate)}
                </h1>

                <div className="flex items-center text-[10px] mt-1">
                  {comparisonMargin < 0 ? (
                    <div className="flex items-center py-1 px-2 bg-rose-100 w-fit rounded-full mr-1 text-rose-600">
                      <HiArrowTrendingDown size={12} className="mr-1" />
                      {comparisonMargin}%
                    </div>
                  ) : (
                    <div className="flex items-center py-1 px-2 bg-emerald-100 w-fit rounded-full mr-1 text-emerald-600">
                      <HiArrowTrendingUp size={12} className="mr-1" />
                      {comparisonMargin}%
                    </div>
                  )}

                  <p>vs yesterday</p>
                </div>

                <TotalMargin data={data.revenue_transaction_success_daily} />
              </div>
            )}
            {user?.role_id === 1 && (
              <div className="bg-gray-100 text-gray-700 w-full lg:w-1/2 flex flex-col rounded-xl pt-4 ">
                <div className="flex items-center gap-x-2 pl-4">
                  <ChartPieIcon className="w-6" />
                  <p className=" ">Top Products</p>
                </div>
                <div className="flex gap-x-4 text-gray-700 items-center h-full flex-grow pt-4">
                  <TopProducts data={productsToday} />
                </div>
              </div>
            )}

            {user?.role_id === 1 && (
              <div className="bg-gray-100 text-gray-700 w-full lg:w-1/2 flex flex-col rounded-xl pt-4 ">
                <div className="flex items-center gap-x-2 pl-4">
                  <BuildingStorefrontIcon className="w-6" />
                  <h3 className="text-sm font-semibold"> Top Resellers</h3>
                </div>
                <div className="flex gap-x-4 text-gray-700 items-center h-full flex-grow pt-4">
                  <TopReseller data={resellersToday} />
                </div>
              </div>
            )}
          </div>
          <DashboardTransaction
            data={data.revenue_transaction_success_daily}
            loading={loading}
            month={month}
            setMonth={setMonth}
            year={year}
            setYear={setYear}
            setRefresh={setRefresh}
            refresh={refresh}
            user={user}
          />
        </div>
      </div>
    </div>
  );
}
