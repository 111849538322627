import React, { useState } from "react";
import {
  getEmailRegex,
  getIdRegex,
  getIpAddressRegex,
  getNpwpRegex,
  getPhoneNoRegex,
} from "../Utils";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axiosRequest from "../api/AxiosRequest";

const useRegistration = () => {
  const [regParam, setRegParam] = useState({
    // email: "yabes.theodorus64@gmail.com",
    // password: "walletku02",
    // name: "Yabes Reseller 1",
    // ownerName: "Yabes Theodorus",
    // address: "infinia park c105",
    // idNumber: "3273211203990003",
    // taxIdNumber: "08.178.554.2-123.321",
    // phoneNumber: "085155287733",
    // pin: "1234",
    // otomaxId: "Dn49sej-xUI",
    // jabberId: "jabber.com/yabes",
    // waNumber: "085133728382",
    // ipAddress: "123.123.223.64",
    // passwordIp: "walletku02",
    // urlReport: "https://www.tesotobpr.com?report=",

    email: "",
    password: "",
    name: "",
    ownerName: "",
    address: "",
    idNumber: "",
    taxIdNumber: "",
    phoneNumber: "",
    pin: "",
    otomaxId: "",
    jabberId: "",
    waNumber: "",
    ipAddress: "",
    passwordIp: "",
    urlReport: "",
  });

  const navigate = useNavigate();

  const [error, setError] = useState({});

  const updateFormValue = (field, value) => {
    const temp = { ...regParam };
    temp[field] = value;
    setRegParam(temp);
  };

  const submitRegistration = async () => {
    try {
      const isValid = validateInput();
      if (isValid) {
        //prepare to call api
        const param = {
          // code: "bpr00345",
          code_area: "wad",
          upline: "jku-012",
          // code_level: "A",
          // markup: "23.0000",
          name: regParam.name,
          owner_name: regParam.ownerName,
          address: regParam.address,
          pin: regParam.pin,
          // description: "holaaaaaaaaa",
          oid: regParam.otomaxId,
          ip_password: regParam.passwordIp,
          ktp: regParam.idNumber,
          ip_address: regParam.ipAddress,
          npwp: regParam.taxIdNumber,
          url_report: regParam.urlReport,
          email: regParam.email,
          password: regParam.password,
          wa_number: regParam.waNumber,
          phone_number: regParam.phoneNumber,
          jabber_id: regParam.jabberId,
        };

        const response = await axiosRequest.post("/api/v1/resellers", param, {
          headers: {
            "Content-Type": "application/json",
            Authorization: sessionStorage.getItem("jwt"),
          },
        });

        navigate("/successPage", { state: { email: regParam.email } });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const validateInput = () => {
    const errorTemp = {};

    if (regParam.name.length < 3) {
      errorTemp.name = "Nama tidak boleh kurang dari 3 karakter";
    }

    if (!getEmailRegex().test(regParam.email)) {
      errorTemp.email = "Format Email Salah";
    }

    if (regParam.password.length < 8) {
      errorTemp.password = "Password tidak boleh kurang dari 8 karakter";
    }

    if (regParam.passwordIp.length < 3) {
      errorTemp.passwordIp = "Password tidak boleh kurang dari 3 karakter";
    }
    if (regParam.urlReport.length < 3) {
      errorTemp.urlReport = "URL Report tidak boleh kurang dari 3 karakter";
    }

    if (regParam.ownerName.length < 3) {
      errorTemp.ownerName = "Nama Owner tidak boleh kurang dari 3 karakter";
    }

    if (regParam.address.length < 3) {
      errorTemp.address = "Alamat tidak boleh kurang dari 3 karakter";
    }

    if (!getIdRegex().test(regParam.idNumber)) {
      errorTemp.idNumber = "Format KTP salah";
    }

    if (!getNpwpRegex().test(regParam.taxIdNumber)) {
      errorTemp.taxIdNumber = "Format NPWP salah";
    }

    if (!getPhoneNoRegex().test(regParam.phoneNumber)) {
      errorTemp.phoneNumber = "Format No Handphone salah";
    }

    if (!/^[0-9]*$/.test(regParam.pin) || regParam.pin.length < 4) {
      errorTemp.pin = "Format PIN salah";
      //   alert(!/ ^[0-9]*$/.test(regParam.pin));
    }

    if (!getPhoneNoRegex().test(regParam.waNumber)) {
      errorTemp.waNumber = "Format No Whatsapp salah";
    }

    if (!getIpAddressRegex().test(regParam.ipAddress)) {
      errorTemp.ipAddress = "Format IP Address salah";
    }

    setError(errorTemp);
    return Object.keys(errorTemp).length === 0;
  };

  return {
    regParam,
    updateFormValue,
    error,
    validateInput,
    submitRegistration,
  };
};

export default useRegistration;
