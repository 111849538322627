import React from "react";

function TransactionSkeleton(props) {
  return [1, 2, 3].map((transaction, index) => {
    return (
      <tr className="border-b border-gray-200 hover:bg-gray-100" key={index}>
        {[1, 2, 3, 4, 5].map((item, index) => (
          <td className="py-3  text-left whitespace-nowrap">
            <div className="flex items-center w-full">
              <div className="ml-4">
                <div className="animate-pulse  h-4 w-36 rounded bg-gray-300"></div>
              </div>
            </div>
          </td>
        ))}
      </tr>
    );
  });
}

export default TransactionSkeleton;
