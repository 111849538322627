import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";

export default function DashboardTab({
  isDrawerOpen,
  openDrawer,
  closeDrawer,
}) {
  const location = useLocation();
  const { pathname } = location;
  return (
    <div className="flex gap-x-4 items-center">
      <button
        onClick={isDrawerOpen ? closeDrawer : openDrawer}
        className="md:hidden mr-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
      >
        {isDrawerOpen ? (
          <XMarkIcon className="h-8 w-8 stroke-2" color="bg-primaryColor" />
        ) : (
          <Bars3Icon className="h-8 w-8 stroke-2" color="bg-primaryColor" />
        )}
      </button>
      <NavLink
        to="/dashboard"
        className={`px-6 py-2 rounded-lg hover:text-white ${
          pathname === "/dashboard"
            ? "bg-primaryColor text-white "
            : "bg-gray-100 text-primaryColor"
        }  border border-gray-200 hover:bg-primaryColor/80`}
      >
        Monthly
      </NavLink>

      <NavLink
        to="/dashboard-today"
        className={`px-6 py-2 rounded-lg hover:text-white ${
          pathname === "/dashboard-today"
            ? "bg-primaryColor text-white "
            : "bg-gray-100 text-primaryColor"
        }  border border-gray-200 hover:bg-primaryColor/80`}
      >
        Daily
      </NavLink>
    </div>
  );
}
