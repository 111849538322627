import { CheckIcon } from "@heroicons/react/20/solid";
import {
  AdjustmentsHorizontalIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ApprovalDetail from "../ApprovalDetail";
import useGetApprovalList from "../../../hooks/useGetApprovalList";

export default function ApprovalList() {
  const { approvalList, approvalListFiltered, filterListByStatus } =
    useGetApprovalList();

  const [selectedData, setSelectedData] = useState(null);

  const renderApprovalList = () => {
    return approvalListFiltered.map((data) => {
      return (
        <tr
          key={data.attributes.id}
          onClick={() => {
            document.getElementById("my_modal_2").showModal();
            setSelectedData(data);
          }}
          className="hover:bg-gray-200 hover:cursor-pointer text-gray-700"
        >
          <td className="px-4 py-8">{data.attributes.name} </td>
          <td className="px-4">{data.attributes.owner_name}</td>
          <td className="px-4">{data.attributes.address}</td>
          <td className="px-4">{data.attributes.email}</td>

          {data.attributes.status === "approved" ? (
            <td className="px-4 ">
              <div className="px-4 py-2 bg-emerald-600 rounded-lg flex w-fit items-center text-white">
                <CheckIcon className="w-4 mr-2" />
                {data.attributes.status}
              </div>
            </td>
          ) : data.attributes.status === "rejected" ? (
            <td className="px-4 ">
              <div className="px-4 py-2 bg-rose-600 rounded-lg flex w-fit items-center text-white">
                <XMarkIcon className="w-4 mr-2" />
                {data.attributes.status}
              </div>
            </td>
          ) : (
            <td className="px-4 ">
              <div className="px-4 py-2 bg-amber-600 rounded-lg flex w-fit items-center text-white">
                <ExclamationCircleIcon className="w-5 mr-2" />
                {data.attributes.status}
              </div>
            </td>
          )}
        </tr>
      );
    });
  };
  return (
    <div className="w-full p-6 bg-white rounded-lg min-h-[450px] ">
      <div className="flex items-center gap-x-8">
        <p className="font-bold text-lg text-primaryColor">Approval List</p>

        <div className="flex items-center pl-3 rounded-lg gap-x-1 bg-primaryColor text-white">
          <AdjustmentsHorizontalIcon className="w-5 " />
          <select
            defaultValue={"all"}
            onChange={(e) => filterListByStatus(e.target.value)}
            className="select select-sm bg-transparent border-0 focus:outline-none focus:ring-0"
          >
            <option value={"all"}>All</option>
            <option value={"pending"}>Pending</option>
            <option value={"approved"}>Approved</option>
            <option value={"rejected"}>Rejected</option>
          </select>
        </div>
      </div>
      <ApprovalDetail data={selectedData} />
      <table className="w-full p-4 bg-gray-100 rounded-lg overflow-hidden mt-6">
        <thead className="text-gray-600 bg-gray-300">
          <tr>
            <th className="p-4 text-left">Reseller Name</th>
            <th className="p-4 text-left">Owner Name</th>
            <th className="p-4 text-left">Address</th>
            <th className="p-4 text-left">Email</th>
            <th className="p-4 text-left">Status</th>
          </tr>
        </thead>

        <tbody>{renderApprovalList()}</tbody>
      </table>
    </div>
  );
}
