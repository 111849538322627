import React from "react";
import { numberFormatWithoutCurr } from "../../../Utils";
import { isAllowed } from "../../../Utils";

export default function TransactionSummaryItem({ transaction, month, user }) {
  return (
    <tr
      key={(Math.random() * 100).toFixed(0)}
      className="bg-white border-b  hover:bg-gray-50 even:bg-gray-100 "
    >
      <td className="px-6 py-4">{`${transaction.date.substring(5)}`}</td>

      <td className="px-6 py-4">
        {numberFormatWithoutCurr(transaction.revenue)}
      </td>

      <td className="px-6 py-4">
        {numberFormatWithoutCurr(transaction.total_transaction)}
      </td>
      {isAllowed([1], user) && (
        <td className="px-6 py-4">
          {numberFormatWithoutCurr(transaction.margin)}
        </td>
      )}

      {isAllowed([1], user) && (
        <td className="px-6 py-4">
          {parseFloat((transaction.margin / transaction.revenue) * 100).toFixed(
            2
          )}
        </td>
      )}
    </tr>
  );
}
