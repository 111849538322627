import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { formatNumber } from "../../../Utils";

export default function TotalSales({ data, totalHit }) {
  const [revenue, setRevenue] = useState([]);
  const [months, setMonths] = useState([]);
  const [hit, setHit] = useState([]);

  useEffect(() => {
    if (data) {
      setRevenue(data.map((item) => item.revenue));
      setMonths(data.map((item) => item.month));
      setHit(data.map((item) => item.total_transaction));
    }
  }, [data]);

  var options = {
    series: [
      {
        name: totalHit ? "Total Hit" : "Revenue",
        data: totalHit ? hit : revenue,
      },
    ],
    chart: {
      height: 350,
      type: "bar",
      fontFamily: "Poppins, sans-serif",
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    fill: {
      colors: ["#2c3364"],
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: months,
      position: "bottom",

      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.7,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return formatNumber(val);
        },
      },
    },
    title: {
      text: "Total Transactions",
      floating: true,
      offsetY: 330,
      align: "center",
      style: {
        color: "#444",
      },
    },
    grid: {
      padding: {
        bottom: -10,
      },
      show: true,
      borderColor: "#90A4AE",
      strokeDashArray: 4,
    },
  };

  return (
    <Chart
      options={options}
      series={options.series}
      type="bar"
      height={250}
      width="100%"
    />
  );
}
