import { CheckBadgeIcon, EnvelopeIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useLocation } from "react-router-dom";

function RegistrationSuccess(props) {
  const { state } = useLocation();
  const { email } = state;
  return (
    <div className="relative bg-gray-100 h-screen font-poppins pb-12">
      <div className="bg-primaryColor w-full h-[125px]"></div>

      <div className="bg-transparent flex justify-center w-full gap-x-4 -mt-20 ">
        <div className="flex flex-col px-8 rounded-lg py-16 bg-white max-w-4xl w-[850px] h-fit gap-y-4 items-center">
          <CheckBadgeIcon className="text-emerald-500 w-24 " />
          <h1 className="text-2xl font-bold uppercase mt-4">
            Pendaftaran Sukses
          </h1>
          <p className="text-center text-lg mt-4 leading-1 tracking-wide w-3/4 mx-auto">
            Terima kasih atas pendaftaran anda! Data anda telah kami terima dan
            akan segera diproses. Mohon perhatikan email anda untuk pembaruan
            lebih lanjut dari kami.
          </p>
          <p className="text-center text-lg mt-4 leading-2 tracking-wide w-3/4 mx-auto">
            Email Terdaftar :
          </p>

          <div className="mx-auto w-fit px-6 py-2  flex items-center gap-x-4 rounded-full border border-amber-500">
            <EnvelopeIcon className="w-8 text-amber-500" />
            {email}
          </div>

          <button className="px-6 py-2 rounded-lg bg-primaryColor text-white font-semibold mt-8">
            Kembali
          </button>
        </div>
      </div>
    </div>
  );
}

export default RegistrationSuccess;
