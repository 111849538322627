import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  resellers: [],
  suppliers: [],
  highTransactionTime: [],
  loading: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setTopProductData(state, action) {
      state.products = action.payload;
    },

    setTopResellerData(state, action) {
      state.resellers = action.payload;
    },

    setTopSupplierData(state, action) {
      state.suppliers = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },

    highTransactionTime(state, action) {
      state.highTransactionTime = action.payload;
    },
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice;
