import { dashboardActions } from "./dashboard-slice";
import toast from "react-hot-toast";
import axiosRequest from "../../api/AxiosRequest";

export const fetchTopToday = () => {
  return async (dispatch) => {
    const fetchData = async () => {
      dispatch(dashboardActions.setLoading(true));
      const response = await axiosRequest.get("/api/v2/dashboard/top_today", {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("jwt"),
        },
        params: {
          limit: 10,
        },
      });
      return response.data;
    };

    try {
      const data = await fetchData();
      dispatch(dashboardActions.setTopProductData(data.data.top_product));
      dispatch(dashboardActions.setTopSupplierData(data.data.top_supplier));
      dispatch(dashboardActions.setTopResellerData(data.data.top_reseller));
    } catch (error) {
      toast.error(error);
    } finally {
      dispatch(dashboardActions.setLoading(false));
    }
  };
};

export const fetchTop = (monthYear) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axiosRequest.get("/api/v2/dashboard/top", {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("jwt"),
        },
        params: {
          limit: 10,
          month: monthYear || "",
        },
      });
      return response.data;
    };

    try {
      const data = await fetchData();
      dispatch(dashboardActions.setTopProductData(data.data.products));
      dispatch(dashboardActions.setTopResellerData(data.data.resellers));
    } catch (error) {
      toast.error(error);
    }
  };
};

export const fetchHighTransTime = (monthYear) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const response = await axiosRequest.get(
        "api/v2/dashboard/top_high_transactions_per_hour",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("jwt"),
          },
          params: {
            month: monthYear,
          },
        }
      );
      return response.data;
    };

    try {
      const topHighTransactions = await fetchData();
      dispatch(dashboardActions.highTransactionTime(topHighTransactions.data));
    } catch (error) {
      toast.error(error);
    }
  };
};
