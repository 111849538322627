import React from "react";

export default function Unauthorized() {
  return (
    <div className="w-full h-screen flex  justify-center bg-white font-poppins">
      <div className="flex flex-col items-center gap-y-4">
        <img className="max-w-[600px] my-8" src="/401.png" />
        <h1 className="text-3xl text-primaryColor font-bold">Unauthorized</h1>
        <p className="text-gray-400 max-w-[400px] text-center">
          We're Sorry, you are not allowed to view this page.
        </p>

        <a
          href="/"
          className="px-6 py-2 bg-primaryColor text-white text-lg mt-4 rounded-full"
        >
          Go Home
        </a>
      </div>
    </div>
  );
}
