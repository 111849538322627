import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../context/UserContext";

const ProtectedRoute = ({ roles, children }) => {
  const { user } = useUser();

  if (!user) {
    // Not logged in
    return <Navigate to="/login" />;
  }

  if (roles && roles.indexOf(user.role_id) === -1) {
    // User does not have the required role
    return <Navigate to="/unauthorized" />;
  }

  // Authorized
  return children;
};

export default ProtectedRoute;
