import Chart from "react-apexcharts";
import NoData from "../../../component/NoData";

export default function TodayRevenue({ revenue, loading }) {
  const loadingState = () => {
    return (
      <div className="flex gap-x-4 items-center pb-4 justify-between w-full">
        <div className="animate-pulse w-[150px] h-[150px] mx-auto rounded-full bg-gray-300"></div>
      </div>
    );
  };

  if (loading) {
    return loadingState();
  } else if (!revenue) {
    return (
      <div className="items-center">
        <NoData />
      </div>
    );
  } else {
    return (
      <Chart
        options={revenue.options}
        series={revenue.options.series}
        type="radialBar"
        className="mx-auto scale-[1.3] my-6"
        width="100%"
      />
    );
  }
}
