import React from "react";

function Footer(props) {
  return (
    <footer class="bg-white rounded-lg shadow dark:bg-gray-200 py-12">
      <div class="w-full max-w-7xl mx-auto p-4 md:py-8">
        <div class="sm:flex sm:items-center sm:justify-between">
          <a
            href="https://www.balipulsareload.com/"
            class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse"
          >
            <img className="w-[160px]" src="./logo.jpeg" />
          </a>
          <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-800 sm:mb-0 ">
            <li>
              <a href="#" class="hover:underline me-4 md:me-6">
                About
              </a>
            </li>
            <li>
              <a href="#" class="hover:underline me-4 md:me-6">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="#" class="hover:underline me-4 md:me-6">
                Licensing
              </a>
            </li>
            <li>
              <a href="#" class="hover:underline">
                Contact
              </a>
            </li>
          </ul>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span class="block text-sm text-gray-800 sm:text-center ">
          © 2024
          <a href="https://flowbite.com/" class="hover:underline">
            Bali Pulsa Reload
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}

export default Footer;
