import React, { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import ActionButton from "./components/ActionButton";
import DashboardSidebar from "../dashboard/components/DashboardSidebar";
import {
  MagnifyingGlassCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { SiMicrosoftexcel } from "react-icons/si";
import useGetTransaction from "../../hooks/useGetTransaction";
import {
  ArrowPathIcon,
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import TransactionItem from "./components/TransactionItem";
import TransactionSkeleton from "./components/TransactionSkeleton";
import NoData from "../../component/NoData";
import { exportToExcel } from "../../Utils";

export default function Transaction() {
  const {
    transactions,
    loading,
    value,
    handleValueChange,
    handleStatusChange,
    getTransactionApi,
    idNumber,
    setIdNumber,
    nextPage,
    prevPage,
    firstPage,
    currentPage,
    exportLoading,
  } = useGetTransaction();

  const renderTransaction = () => {
    if (loading) {
      return <TransactionSkeleton />;
    } else if (transactions.length === 0) {
      return <NoData />;
    } else
      return transactions.map((transaction) => {
        return <TransactionItem currentItems={transaction} />;
      });
  };

  return (
    <div className="relative  font-poppins h-screen">
      <div className="bg-transparent flex w-full h-full">
        <DashboardSidebar />

        <div className="flex flex-col flex-grow bg-white h-fit gap-y-4 max-w-full mx-auto p-6">
          <div className="border-b border-dashed border-gray-200 h-20 flex items-center">
            <p className="text-2xl text-gray-700 font-semibold">Transaksi</p>
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              getTransactionApi(0);
            }}
          >
            <div className="flex  items-center w-full py-4 gap-x-4 justify-start border-b border-gray-400 z-50 bg-white">
              <div className="border border-gray-200 rounded-md">
                <Datepicker
                  value={value}
                  inputClassName={
                    "bg-gray-100 p-2 text-xs border-2 focus:border-primaryColor rounded-md focus:outline-none w-[220px]"
                  }
                  startFrom={new Date()}
                  asSingle={false}
                  useRange={true}
                  placeholder={"Tanggal Transaksi"}
                  onChange={handleValueChange}
                  separator={"~"}
                  showShortcuts={true}
                  showFooter={true}
                  displayFormat={"YYYY-MM-DD"}
                  primaryColor={"gray"}
                />
              </div>
              <div className="py-2">
                <input
                  value={idNumber}
                  onChange={(e) => setIdNumber(e.target.value)}
                  type="text"
                  placeholder="Phone Number"
                  class=" w-full rounded-md  px-3.5 py-1 bg-gray-100 text-xs text-gray-900 shadow-sm  placeholder:text-gray-400 border-2 focus:border-primaryColor focus:outline-none duration-300 sm:text-xs sm:leading-6"
                />
              </div>

              <select
                onChange={(e) => {
                  handleStatusChange(e.target.value);
                }}
                className="select  select-sm w-full max-w-[100px] rounded-lg bg-gray-100 text-xs text-primaryColor shadow-sm"
              >
                <option disabled selected>
                  Status
                </option>
                <option value="all">ALL</option>
                <option value="success">SUKSES</option>
                <option value="pending">PENDING</option>
                <option value="failed">FAILED</option>
              </select>

              <button
                type="submit"
                className="flex items-center gap-x-2 rounded-lg py-2 px-4 border border-primaryColor bg-white text-primaryColor hover:bg-primaryColor hover:text-white text-xs"
              >
                <MagnifyingGlassIcon className="w-4" />
                Search
              </button>

              <button
                type="button"
                onClick={(e) => getTransactionApi(1)}
                className="flex items-center gap-x-2 rounded-lg py-2 px-4 border border-primaryColor bg-primaryColor hover:bg-primaryColor/80 text-white text-xs"
              >
                {exportLoading ? (
                  <ArrowPathIcon className="w-4 animate-spin text-white" />
                ) : (
                  <>
                    <SiMicrosoftexcel size={15} className="" />
                    Export
                  </>
                )}
              </button>
            </div>
          </form>

          <div class="relative overflow-x-auto  rounded-lg border border-gray-100 ">
            <table class=" min-w-full text-sm text-left table-auto rtl:text-right text-gray-500 ">
              <thead class="text-xs text-gray-700  bg-gray-100 ">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    ID
                  </th>
                  <th scope="col" class="px-6 py-3">
                    User
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Code Product
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Phone Number
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Transaction Date
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Message
                  </th>
                  {/* <th scope="col" class="px-6 py-3"></th> */}
                </tr>
              </thead>
              <tbody>{renderTransaction()}</tbody>
            </table>
          </div>

          <nav
            class="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4"
            aria-label="Table navigation"
          >
            <span class="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
              Showing
              <span class="font-semibold text-gray-900 dark:text-white">
                1-10
              </span>
              of
              <span class="font-semibold text-gray-900 dark:text-white">
                1000
              </span>
            </span>
            <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
              <li>
                <button
                  onClick={(e) => {
                    prevPage();
                  }}
                  className="flex items-center bg-white justify-center px-3 h-8 ms-0 leading-tight text-gray-500  border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700  "
                >
                  Previous
                </button>
              </li>
              <li>
                <h3 className="flex items-center bg-white justify-center px-3 h-8 ms-0 leading-tight text-gray-500  border border-gray-300  hover:bg-gray-100 hover:text-gray-700  ">
                  {currentPage}
                </h3>
              </li>
              <li>
                <button
                  onClick={(e) => {
                    nextPage();
                  }}
                  className="flex items-center bg-white justify-center px-3 h-8 leading-tight text-gray-500 border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700  "
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
