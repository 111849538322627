import { useState } from "react";
import { getEmailRegex } from "../Utils";
import toast from "react-hot-toast";
import axiosRequest from "../api/AxiosRequest";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useUser } from "../context/UserContext";
const useLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  const navigate = useNavigate();
  const { user, setUser } = useUser();

  const submitLogin = async () => {
    // const param =
    try {
      const isValid = validateInput();
      if (isValid) {
        //prepare to call api
        const param = {
          user: {
            email: email,
            password: password,
          },
        };

        const response = await axiosRequest.post("/login", param);

        const token = response.headers.getAuthorization();
        localStorage.setItem("jwt", token);
        const decoded = jwtDecode(token.split(" ")[1]);
        setUser(decoded);
        navigate("/dashboard");
        // const response2 = await axiosRequest.get("/api/v1/levels", {
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: sessionStorage.getItem("jwt"),
        //   },
        // });
      }
    } catch (error) {
      toast.error(error.response.data.status.message);
    }
  };

  const validateInput = () => {
    const errorTemp = {};

    if (!getEmailRegex().test(email)) {
      errorTemp.email = "Format Email Salah";
    }

    if (password.length < 8) {
      errorTemp.password = "Password tidak boleh kurang dari 8 karakter";
    }

    setError(errorTemp);
    return Object.keys(errorTemp).length === 0;
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    error,
    submitLogin,
  };
};

export default useLogin;
