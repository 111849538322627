import { useState } from "react";
import toast from "react-hot-toast";
import axiosRequest from "../api/AxiosRequest";

const useCreateDeposit = (setRefreshTrigger, refreshTrigger) => {
  const [bank, setBank] = useState("bca");
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);

  const createTicket = async () => {
    setLoading(true);
    try {
      const param = { amount };
      const response = await axiosRequest.post("/api/v1/deposits", param, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("jwt"),
        },
      });
      toast.success("Request Deposit Berhasil");
      setRefreshTrigger(!refreshTrigger);
      setAmount(0);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { bank, setBank, amount, setAmount, createTicket, loading };
};

export default useCreateDeposit;
