import React from "react";
import DashboardSidebar from "../dashboard/components/DashboardSidebar";
import CurrencyInput from "react-currency-input-field";
import { PiHandDeposit } from "react-icons/pi";
import useGetDeposit from "../../hooks/useGetDeposit";
import TransactionSkeleton from "../transaction/components/TransactionSkeleton";
import NoData from "../../component/NoData";
import DepositSkeleton from "./components/DepositSkeleton";
import useCreateDeposit from "../../hooks/useCreateDeposit";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { numberFormatWithoutCurr } from "../../Utils";

export default function Deposit() {
  const { tickets, loading, setRefreshTrigger, refreshTrigger } =
    useGetDeposit();
  const {
    bank,
    setBank,
    amount,
    setAmount,
    createTicket,
    loading: submitLoading,
  } = useCreateDeposit(setRefreshTrigger, refreshTrigger);

  const renderTickets = () => {
    if (loading) {
      return <DepositSkeleton />;
    } else if (tickets.length === 0) {
      return <NoData />;
    } else
      return tickets.map((ticket, idx) => {
        return (
          <tr
            key={idx}
            class="bg-white border-b  hover:bg-gray-50 even:bg-gray-100 "
          >
            <td className="px-6 py-4">
              {new Date(ticket.date_time).toLocaleString("en-US")}
            </td>
            <td className="px-6 py-4">
              Rp. {numberFormatWithoutCurr(ticket.amount)}
            </td>
            <td className="px-6 py-4">
              {ticket.status === "O" ? (
                <div className="px-4 py-2 text-white bg-gray-500 rounded-lg w-fit">
                  Open
                </div>
              ) : ticket.status === "C" ? (
                <div className="px-4 py-2 text-white bg-rose-500 rounded-lg w-fit">
                  Canceled
                </div>
              ) : (
                <div className="px-4 py-2 text-white bg-emerald-500 rounded-lg w-fit">
                  Close
                </div>
              )}
            </td>
          </tr>
        );
      });
  };

  const renderBank = () => {
    return (
      <>
        <img src={`/${bank}.png`} className="h-[50px] w-[150px]" />

        {bank === "bca" ? (
          <div>
            <p className="text-primaryColor">
              Bank Central Asia - <b> 5800565598 </b>
            </p>
            <p className="text-sm">a.n. Bali Pulsa Reload CV</p>
          </div>
        ) : bank === "bri" ? (
          <div>
            <p className="text-primaryColor">
              Bank Rakyat Indonesia - <b> 012201003748304 </b>
            </p>
            <p className="text-sm">a.n. Bali Pulsa Reload CV</p>
          </div>
        ) : (
          <div>
            <p className="text-primaryColor">
              Bank Mandiri - <b> 006008000555 </b>
            </p>
            <p className="text-sm">a.n. Bali Pulsa Reload CV</p>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="relative  font-poppins h-screen">
      <div className="bg-transparent flex w-full h-full">
        <DashboardSidebar />

        <div className="flex flex-col flex-grow bg-white h-fit gap-y-4 max-w-full mx-auto p-6">
          <div className="border-b border-dashed border-gray-200 h-20 flex items-center">
            <p className="text-2xl text-gray-700 font-semibold">
              Deposit Ticket Request
            </p>
          </div>

          <div className="w-full flex items-start gap-x-6 mt-4">
            <div className="flex  flex-col gap-y-4 ">
              <div className="flex flex-col gap-y-4">
                <div className="flex justify-between items-center">
                  <h4 className="text-sm text-gray-400">Transfer ke bank : </h4>

                  <select
                    onChange={(e) => setBank(e.target.value)}
                    className="select  select-sm w-full max-w-[200px] rounded-lg bg-gray-100 text-xs text-primaryColor shadow-sm"
                  >
                    <option selected disabled>
                      Pilih Bank
                    </option>
                    <option value="bca">BCA - (Otomatis) </option>
                    <option value="bri">BRI - (Manual)</option>
                    <option value="mandiri">Mandiri - (Manual)</option>
                  </select>
                </div>

                {renderBank()}
              </div>

              <div className="flex items-start gap-x-6">
                <div className="flex flex-col gap-y-2">
                  <CurrencyInput
                    value={amount}
                    className="bg-gray-100 px-4 py-2 rounded-lg focus:outline-none border-2 focus:border-primaryColor duration-300 text-xs text-gray-700 w-[250px]"
                    placeholder="Input Deposit Amount"
                    defaultValue={0}
                    decimalsLimit={0}
                    prefix="Rp. "
                    onValueChange={(value, name, values) =>
                      // console.log(value, name, values)
                      setAmount(value)
                    }
                  />

                  <h4 className="text-gray-400 text-xs ml-1">
                    Minimum Topup : Rp. 1.000.000
                  </h4>
                </div>

                <button
                  onClick={(e) => {
                    createTicket();
                  }}
                  className="px-6 py-2 text-white text-sm rounded-lg flex items-center bg-primaryColor gap-x-2 hover:scale-105 duration-300 hover:bg-primaryColor/80"
                >
                  {submitLoading ? (
                    <ArrowPathIcon className="w-5 text-white animate-spin" />
                  ) : (
                    <>
                      <PiHandDeposit size={20} />
                      Create
                    </>
                  )}
                </button>
              </div>
            </div>

            <div className="divider divider-horizontal divider-primary"></div>
            <div className="flex flex-col flex-grow gap-y-4 rounded-lg overflow-x-auto border border-gray-200">
              <table class=" min-w-full text-sm text-left table-auto rtl:text-right text-gray-500 ">
                <thead class="text-xs text-gray-700  bg-gray-100 ">
                  <tr>
                    <th scope="col" class="px-6 py-3">
                      Tanggal
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Jumlah
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>{renderTickets()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
