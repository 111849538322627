import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../store/dashboard/dashboard-slice";
import {
  fetchHighTransTime,
  fetchTop,
} from "../store/dashboard/dashboard-actions";
const useReduxDashboard = () => {
  const [month, setMonth] = useState(
    (new Date().getMonth() + 1).toString().padStart(2, "0")
  );
  const [year, setYear] = useState(new Date().getFullYear());
  const [monthYear, setMonthYear] = useState(`${month}-${year}`);
  const dispatch = useDispatch();
  const products = useSelector((state) => state.dashboard.products);
  const resellers = useSelector((state) => state.dashboard.resellers);
  const highTransactionTime = useSelector(
    (state) => state.dashboard.highTransactionTime
  );

  const filterMonthYear = (filter) => {
    setMonthYear(filter);
  };

  useEffect(() => {
    try {
      dispatch(dashboardActions.setLoading(true));
      dispatch(fetchTop(monthYear));
      dispatch(fetchHighTransTime(monthYear));
    } finally {
      dispatch(dashboardActions.setLoading(false));
    }
  }, [monthYear, dispatch]);

  return {
    products,
    resellers,
    filterMonthYear,
    setMonthYear,
    highTransactionTime,
  };
};

export default useReduxDashboard;
