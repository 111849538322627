import React from "react";

export default function DepositSkeleton() {
  return [1, 2, 3].map((transaction) => {
    return (
      <tr class="border-b border-gray-200 hover:bg-gray-100">
        <td class="py-3  text-left whitespace-nowrap">
          <div class="flex items-center w-full">
            <div class="ml-4">
              <div class="animate-pulse  h-4 w-36 rounded bg-gray-300"></div>
            </div>
          </div>
        </td>

        <td class="py-3 text-left whitespace-nowrap">
          <div class="flex items-center w-full">
            <div class="ml-4">
              <div class="animate-pulse h-4 w-28 rounded bg-gray-300"></div>
            </div>
          </div>
        </td>

        <td class="py-3 text-left whitespace-nowrap ">
          <div class="flex items-center w-full">
            <div class="ml-4">
              <div class="animate-pulse h-4 w-64 rounded bg-gray-300"></div>
            </div>
          </div>
        </td>
      </tr>
    );
  });
}
