import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTopToday } from "../store/dashboard/dashboard-actions";
const useReduxDashboardToday = () => {
  const dispatch = useDispatch();
  const productsToday = useSelector((state) => state.dashboard.products);
  const resellersToday = useSelector((state) => state.dashboard.resellers);
  const suppliersToday = useSelector((state) => state.dashboard.suppliers);

  useEffect(() => {
    dispatch(fetchTopToday());
  }, [dispatch]);

  return {
    productsToday,
    resellersToday,
    suppliersToday,
  };
};

export default useReduxDashboardToday;
