import {
  CodeBracketSquareIcon,
  CursorArrowRaysIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import useRegistration from "../../hooks/useRegistration";

export default function Registration() {
  const {
    regParam,
    updateFormValue,
    error: errorForm,
    submitRegistration,
  } = useRegistration();

  return (
    <div className="relative bg-gray-100 font-poppins pb-12 text-primaryColor">
      <div className="bg-primaryColor w-full h-[125px]"></div>

      <div className="bg-transparent flex justify-center w-full gap-x-4 -mt-20 ">
        <div className="flex flex-col px-8 pb-16 rounded-lg bg-white max-w-4xl w-[850px] h-fit gap-y-4   ">
          <div className="border-b border-dashed border-gray-200 h-20 flex justify-between items-center">
            <p className="text-2xl "> Pendaftaran</p>
            <CursorArrowRaysIcon className="w-12 text-amber-400" />
          </div>
          <div>
            Lengkapi form di bawah ini untuk melakukan pendaftaran reseller
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitRegistration();
            }}
            className="mt-4 gap-y-6 flex flex-col"
          >
            <div className="flex items-center gap-x-4 w-full my-4">
              <p className="text-primaryColor">Informasi Akun</p>
              <div className="flex-grow h-1 bg-primaryColor rounded-full"></div>
            </div>

            <div className="flex gap-x-4 items-start">
              <div className="w-1/2">
                <label class="flex text-sm font-semibold leading-6 text-gray-900">
                  Email
                  <p className="ml-2 text-gray-400 italic">
                    (Pastikan email aktif)
                  </p>
                </label>
                <div class="mt-2.5">
                  <input
                    type="text"
                    value={regParam.email}
                    onChange={(e) => updateFormValue("email", e.target.value)}
                    placeholder="Email Reseller"
                    className="block w-full rounded-md bg-gray-100 border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                  />
                </div>
                {errorForm.email ? (
                  <p className="text-rose-500 text-sm px-2 mt-1">
                    {errorForm.email}
                  </p>
                ) : null}
              </div>

              <div className="w-1/2">
                <label class="flex text-sm font-semibold leading-6 text-gray-900">
                  Password
                  <p className="ml-2 text-gray-400 italic">(Password akun)</p>
                </label>
                <div class="mt-2.5">
                  <input
                    type="password"
                    value={regParam.password}
                    onChange={(e) =>
                      updateFormValue("password", e.target.value)
                    }
                    placeholder="Password"
                    class="block w-full rounded-md border-0 bg-gray-100 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                  />
                </div>
                {errorForm.password ? (
                  <p className="text-rose-500 text-sm px-2 mt-1">
                    {errorForm.password}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="flex items-center gap-x-4 w-full my-4">
              <p className="text-primaryColor">Informasi Umum</p>
              <div className="flex-grow h-1 bg-primaryColor rounded-full"></div>
            </div>
            <div className="flex gap-x-4 items-start">
              <div className="w-1/2">
                <label class="flex text-sm font-semibold leading-6 text-gray-900">
                  Nama
                  <p className="ml-2 text-gray-400 italic">
                    (Bali Pulsa Reload)
                  </p>
                </label>
                <div class="mt-2.5">
                  <input
                    type="text"
                    value={regParam.name}
                    onChange={(e) => updateFormValue("name", e.target.value)}
                    placeholder="Nama Reseller"
                    class="block w-full rounded-md border-0 bg-gray-100 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                  />
                </div>
                {errorForm.name ? (
                  <p className="text-rose-500 text-sm px-2 mt-1">
                    {errorForm.name}
                  </p>
                ) : null}
              </div>

              <div className="w-1/2">
                <label class="flex text-sm font-semibold leading-6 text-gray-900">
                  Nama Owner
                  <p className="ml-2 text-gray-400 italic">(Budi Makmur)</p>
                </label>
                <div class="mt-2.5">
                  <input
                    type="text"
                    value={regParam.ownerName}
                    onChange={(e) =>
                      updateFormValue("ownerName", e.target.value)
                    }
                    placeholder="Nama Owner"
                    class="block w-full rounded-md border-0 bg-gray-100 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                  />
                </div>
                {errorForm.ownerName ? (
                  <p className="text-rose-500 text-sm px-2 mt-1">
                    {errorForm.ownerName}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="w-full">
              <label
                for="name"
                class="flex text-sm font-semibold leading-6 text-gray-900"
              >
                Alamat
                <p className="ml-2 text-gray-400 italic">
                  (Jl. Soekarno Hatta No. xx, Kel. xxx, Kec. xxx, Kota,
                  Provinsi)
                </p>
              </label>
              <div class="mt-2.5">
                <input
                  type="text"
                  value={regParam.address}
                  onChange={(e) => updateFormValue("address", e.target.value)}
                  placeholder="Alamat Reseller"
                  class="block w-full rounded-md border-0 bg-gray-100 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                />
              </div>
              {errorForm.address ? (
                <p className="text-rose-500 text-sm px-2 mt-1">
                  {errorForm.address}
                </p>
              ) : null}
            </div>
            <div className="flex gap-x-4 items-start">
              <div className="w-1/4">
                <label class="flex text-sm font-semibold leading-6 text-gray-900">
                  ID/KTP
                  <p className="ml-2 text-gray-400 italic">(Nomor Identitas)</p>
                </label>
                <div class="mt-2.5">
                  <input
                    type="text"
                    value={regParam.idNumber}
                    onChange={(e) =>
                      updateFormValue("idNumber", e.target.value)
                    }
                    placeholder="ID/KTP Owner"
                    class="block w-full rounded-md border-0 bg-gray-100 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                  />
                </div>

                {errorForm.idNumber ? (
                  <p className="text-rose-500 text-sm px-2 mt-1">
                    {errorForm.idNumber}
                  </p>
                ) : null}
              </div>

              <div className="w-5/12">
                <label class="flex text-sm font-semibold leading-6 text-gray-900">
                  NPWP
                  <p className="ml-2 text-gray-400 italic">
                    (Bagi Pengusaha Kena Pajak)
                  </p>
                </label>
                <div class="mt-2.5">
                  <input
                    type="text"
                    value={regParam.taxIdNumber}
                    onChange={(e) =>
                      updateFormValue("taxIdNumber", e.target.value)
                    }
                    placeholder="NPWP Reseller"
                    class="block w-full rounded-md border-0 bg-gray-100 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                  />
                </div>

                {errorForm.taxIdNumber ? (
                  <p className="text-rose-500 text-sm px-2 mt-1">
                    {errorForm.taxIdNumber}
                  </p>
                ) : null}
              </div>

              <div className="w-1/3">
                <label class="flex text-sm font-semibold leading-6 text-gray-900">
                  No. HP
                  <p className="ml-2 text-gray-400 italic">
                    (Transaksi via SMS)
                  </p>
                </label>
                <div class="mt-2.5">
                  <input
                    type="text"
                    value={regParam.phoneNumber}
                    onChange={(e) =>
                      updateFormValue("phoneNumber", e.target.value)
                    }
                    placeholder="No Handphone"
                    class="block w-full rounded-md border-0 bg-gray-100 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                  />
                </div>

                {errorForm.phoneNumber ? (
                  <p className="text-rose-500 text-sm px-2 mt-1">
                    {errorForm.phoneNumber}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="flex items-center gap-x-4 w-full my-4">
              <p className="text-primaryColor">Informasi Teknis</p>
              <div className="flex-grow h-1 bg-primaryColor rounded-full"></div>
            </div>

            <div className="flex gap-x-4 items-start">
              <div className="w-1/2">
                <label class="flex text-sm font-semibold leading-6 text-gray-900">
                  PIN
                  <p className="ml-2 text-gray-400 italic">(4 - 6 digit PIN)</p>
                </label>
                <div class="mt-2.5">
                  <input
                    type="text"
                    value={regParam.pin}
                    onChange={(e) => updateFormValue("pin", e.target.value)}
                    placeholder="PIN Transaksi"
                    class="block w-full rounded-md border-0 bg-gray-100 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                  />
                </div>
                {errorForm.pin ? (
                  <p className="text-rose-500 text-sm px-2 mt-1">
                    {errorForm.pin}
                  </p>
                ) : null}
              </div>

              <div className="w-1/2">
                <label
                  for="owner-name"
                  class="flex text-sm font-semibold leading-6 text-gray-900"
                >
                  OtomaX ID
                  <p className="ml-2 text-gray-400 italic">(ID OtomaX Anda)</p>
                </label>
                <div class="mt-2.5">
                  <input
                    type="text"
                    value={regParam.otomaxId}
                    onChange={(e) =>
                      updateFormValue("otomaxId", e.target.value)
                    }
                    placeholder="ID OtomaX"
                    class="block w-full rounded-md border-0 bg-gray-100 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                  />
                </div>
                {errorForm.otomaxId ? (
                  <p className="text-rose-500 text-sm px-2 mt-1">
                    {errorForm.otomaxId}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex gap-x-4 items-start">
              <div className="w-1/2">
                <label class="flex text-sm font-semibold leading-6 text-gray-900">
                  Jabber ID
                  <p className="ml-2 text-gray-400 italic">
                    (Transaksi via Jabber)
                  </p>
                </label>
                <div class="mt-2.5">
                  <input
                    type="text"
                    value={regParam.jabberId}
                    onChange={(e) =>
                      updateFormValue("jabberId", e.target.value)
                    }
                    placeholder="ID Jabber"
                    class="block w-full rounded-md border-0 bg-gray-100 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                  />
                </div>
                {errorForm.jabberId ? (
                  <p className="text-rose-500 text-sm px-2 mt-1">
                    {errorForm.jabberId}
                  </p>
                ) : null}
              </div>

              <div className="w-1/2">
                <label class="flex text-sm font-semibold leading-6 text-gray-900">
                  WA Number
                  <p className="ml-2 text-gray-400 italic">
                    (Transaksi via WA)
                  </p>
                </label>
                <div class="mt-2.5">
                  <input
                    type="text"
                    value={regParam.waNumber}
                    onChange={(e) =>
                      updateFormValue("waNumber", e.target.value)
                    }
                    placeholder="Nomor WA"
                    class="block w-full rounded-md border-0 bg-gray-100 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                  />
                </div>
                {errorForm.waNumber ? (
                  <p className="text-rose-500 text-sm px-2 mt-1">
                    {errorForm.waNumber}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex gap-x-4 items-start">
              <div className="w-1/2">
                <label class="flex text-sm font-semibold leading-6 text-gray-900">
                  IP Address
                  <p className="ml-2 text-gray-400 italic">
                    (Alamat IP server anda)
                  </p>
                </label>
                <div class="mt-2.5">
                  <input
                    type="text"
                    value={regParam.ipAddress}
                    onChange={(e) =>
                      updateFormValue("ipAddress", e.target.value)
                    }
                    placeholder="IP Address"
                    class="block w-full rounded-md border-0 bg-gray-100 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                  />
                </div>
                {errorForm.ipAddress ? (
                  <p className="text-rose-500 text-sm px-2 mt-1">
                    {errorForm.ipAddress}
                  </p>
                ) : null}
              </div>

              <div className="w-1/2">
                <label class="flex text-sm font-semibold leading-6 text-gray-900">
                  Password IP
                  <p className="ml-2 text-gray-400 italic">(Password IP )</p>
                </label>
                <div class="mt-2.5">
                  <input
                    type="password"
                    value={regParam.passwordIp}
                    onChange={(e) =>
                      updateFormValue("passwordIp", e.target.value)
                    }
                    placeholder="Password IP"
                    class="block w-full rounded-md border-0 bg-gray-100 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                  />
                </div>
                {errorForm.passwordIp ? (
                  <p className="text-rose-500 text-sm px-2 mt-1">
                    {errorForm.passwordIp}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="w-full">
              <label class="flex text-sm font-semibold leading-6 text-gray-900">
                URL Report
                <p className="ml-2 text-gray-400 italic">(URL Report )</p>
              </label>
              <div class="mt-2.5">
                <input
                  type="text"
                  value={regParam.urlReport}
                  onChange={(e) => updateFormValue("urlReport", e.target.value)}
                  placeholder="URL Report"
                  class="block w-full rounded-md border-0 bg-gray-100 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                />
              </div>
              {errorForm.urlReport ? (
                <p className="text-rose-500 text-sm px-2 mt-1">
                  {errorForm.urlReport}
                </p>
              ) : null}
            </div>

            <button
              type="submit"
              className="rounded-lg w-full py-2 bg-primaryColor text-white"
            >
              Submit Registration
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
