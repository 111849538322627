import React from "react";

function NoData() {
  return (
    <tr key={(Math.random() * 100).toFixed(0)}>
      <td
        colSpan="7"
        className="py-12 text-md text-gray-500 font-poppins text-center"
      >
        <p>No Data Found</p>
      </td>
    </tr>
  );
}

export default NoData;
