import Chart from "react-apexcharts";
import { useState, useEffect } from "react";
import { formatNumber } from "../../../Utils";
import { useLocation } from "react-router-dom";
export default function TotalMargin({ data }) {
  const [margin, setMargin] = useState([]);
  const [months, setMonths] = useState([]);

  const location = useLocation();
  const isMonthly = () => {
    return location.pathname === "/dashboard";
  };

  useEffect(() => {
    if (data) {
      if (isMonthly()) {
        setMargin(data.map((item) => item.margin));
        setMonths(data.map((item) => item.month));
      } else {
        const arrayData =
          data.length >= 8
            ? data.slice(0, 8).reverse()
            : data.slice(0, data.length).reverse();
        setMargin(arrayData.map((item) => item.margin));
        setMonths(arrayData.map((item) => item.date.substring(8)));
      }
    }
  }, [data]);

  var options = {
    series: [
      {
        name: "Margin",
        data: margin,
      },
    ],
    chart: {
      height: 350,
      type: "bar",
      fontFamily: "Poppins, sans-serif",
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    fill: {
      colors: ["#2c3364"],
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: months,
      position: "bottom",

      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.7,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return formatNumber(val);
        },
      },
    },
    title: {
      text: "Total Margins",
      floating: true,
      offsetY: 330,
      align: "center",
      style: {
        color: "#444",
      },
    },
    grid: {
      padding: {
        bottom: -10,
      },
      show: true,
      borderColor: "#90A4AE",
      strokeDashArray: 4,
    },
  };

  return (
    <Chart
      options={options}
      series={options.series}
      type="bar"
      height={250}
      width="100%"
    />
  );
}
