import { UserIcon } from "@heroicons/react/16/solid";
import React from "react";
import useLogin from "../../hooks/useLogin";

export default function Login() {
  const { email, setEmail, password, setPassword, error, submitLogin } =
    useLogin();

  return (
    <div className="relative bg-gray-100 font-poppins pb-12">
      <div className="bg-primaryColor w-full h-[125px]"></div>

      <div className="bg-transparent flex justify-center w-full gap-x-4 -mt-20 ">
        <div className="flex flex-col px-8 pb-16 rounded-lg bg-white max-w-xl w-[850px] h-fit gap-y-4">
          <div className="border-b border-dashed border-gray-200 h-20 flex justify-between items-center">
            <p className="text-2xl "> Login</p>
            <UserIcon className="w-8 text-amber-400" />
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitLogin();
            }}
          >
            <div className="flex flex-col gap-y-6">
              <div className="">
                <label class="flex text-sm font-semibold leading-6 text-gray-900">
                  Email :
                </label>
                <div class="mt-2.5">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    placeholder="Email"
                    class="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                  />
                </div>
                {error.email ? (
                  <p className="text-rose-500 text-sm px-2 mt-1">
                    {error.email}
                  </p>
                ) : null}
              </div>

              <div className="">
                <label class="flex text-sm font-semibold leading-6 text-gray-900">
                  Password :
                </label>
                <div class="mt-2.5">
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    placeholder="Password"
                    class="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                  />
                </div>
                {error.password ? (
                  <p className="text-rose-500 text-sm px-2 mt-1">
                    {error.password}
                  </p>
                ) : null}
              </div>

              {/* <a
                href="/forgot-password"
                className="-mt-3 ml-1 text-primaryColor text-xs hover:underline w-fit "
              >
                Forgot Password
              </a> */}

              <button
                type="submit"
                className="bg-primaryColor rounded-lg text-white px-6 py-2"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
