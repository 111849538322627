import { useEffect, useState } from "react";
import axiosRequest from "../api/AxiosRequest";

const useGetLevel = () => {
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    const fetchLevel = async () => {
      const response = await axiosRequest.get("/api/v1/levels", {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("jwt"),
        },
      });

      setLevels(response.data.data);
    };

    fetchLevel();
  }, []);

  return { levels };
};

export default useGetLevel;
