import React, { useState } from "react";
import DashboardSidebar from "./components/DashboardSidebar";
import { useUser } from "../../context/UserContext";
import { numberFormatWithoutCurr } from "../../Utils";
import {
  CurrencyDollarIcon,
  CursorArrowRaysIcon,
  ExclamationCircleIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import useGetDashboard from "../../hooks/useGetDashboard";
import TotalSales from "./components/TotalSales";
import TodayRevenue from "./components/TodayRevenue";
import { PiCoinsBold } from "react-icons/pi";
import { MdAdsClick } from "react-icons/md";
import { HiArrowTrendingDown, HiArrowTrendingUp } from "react-icons/hi2";
import DashboardTransaction from "./components/DashboardTransaction";
import useReduxDashboard from "../../hooks/useReduxDashboard";
import { BuildingStorefrontIcon } from "@heroicons/react/16/solid";
import { ChartPieIcon } from "@heroicons/react/16/solid";
import DashboardTab from "./components/DashboardTab";
import { FaFilter } from "react-icons/fa6";
import { generateMonthArray } from "../../Utils";
import TopProducts from "./components/TopProducts";
import TopReseller from "./components/TopReseller";
import TopTransactionTime from "./components/TopTransactionTime";
import TotalMargin from "./components/TotalMargin";
import { DashboardDrawer } from "./components/DashboardDrawer";

export default function Welcome() {
  const { user, balance } = useUser();

  const isAllowed = (allowedRoles) => {
    return allowedRoles.includes(user?.role_id);
  };

  const {
    loading,
    data,
    trxTrending,
    hitTrending,
    refresh,
    setRefresh,
    month,
    setMonth,
    year,
    setYear,
    revenue,
    comparisonRevenue,
    lastMonthComparison,
    revenueByDate,
    marginByDate,
    comparisonMargin,
  } = useGetDashboard();

  const { filterMonthYear, products, resellers, highTransactionTime } =
    useReduxDashboard();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => setIsDrawerOpen(true);

  const closeDrawer = () => setIsDrawerOpen(false);

  return (
    <div className="flex-grow font-poppins bg-gray-50">
      <div className="bg-transparent flex w-full h-full">
        <DashboardSidebar />
        <DashboardDrawer
          isDrawerOpen={isDrawerOpen}
          closeDrawer={closeDrawer}
          openDrawer={openDrawer}
        />

        <div className="flex flex-col flex-grow gap-y-4 max-w-7xl mx-auto p-6 overflow-y-auto">
          <DashboardTab
            isDrawerOpen={isDrawerOpen}
            openDrawer={openDrawer}
            closeDrawer={closeDrawer}
          />
          <p className="text-xl text-gray-800 font-semibold">Insight</p>
          <div className="flex flex-col lg:flex-row gap-4 ">
            <div className="flex items-center lg:w-[450px] bg-primaryColor rounded-xl text-white p-4 justify-between">
              <div>
                <p className="text-sm">Saldo Anda</p>
                <p className="text-xl lg:text-2xl font-bold">
                  Rp {numberFormatWithoutCurr(balance)}
                </p>
              </div>

              <a
                href="/deposit_request"
                className="bg-white flex items-center text-primaryColor text-md p-4 py-1 rounded-full w-fit h-fit"
              >
                <CurrencyDollarIcon className="w-4 mr-2" />
                Request Deposit
              </a>
            </div>
            <div className="flex flex-col lg:flex-row items-start lg:items-center flex-grow bg-primaryColor rounded-xl text-white p-4 lg:justify-between">
              <h1>
                Today's <br /> Transaction Status
              </h1>
              <div className="hidden text-white items-center lg:flex pl-4 pr-8 py-1.5 rounded-lg">
                <CursorArrowRaysIcon className="w-6 mr-3" />
                <div className="flex flex-col">
                  <p className=" text-bottom text-sm font-semibold">Success</p>
                  <p className=" text-bottom text-lg font-semibold">
                    {numberFormatWithoutCurr(data.success_count)}
                  </p>
                </div>
              </div>
              <div className="hidden text-white items-center lg:flex pl-4 pr-8 py-1.5 rounded-lg">
                <ExclamationCircleIcon className="w-6 mr-3" />
                <div className="flex flex-col">
                  <p className=" text-bottom text-sm font-semibold">Failed</p>
                  <p className=" text-bottom text-lg font-semibold">
                    {numberFormatWithoutCurr(data.failed_count)}
                  </p>
                </div>
              </div>
              <div className="hidden text-white items-center lg:flex pl-4 pr-8 py-1.5 rounded-lg">
                <ClockIcon className="w-6 mr-3" />
                <div className="flex flex-col">
                  <p className=" text-bottom text-sm font-semibold">Pending</p>
                  <p className=" text-bottom text-lg font-semibold">
                    {data.pending_count}
                  </p>
                </div>
              </div>
              <div className="lg:hidden flex flex-row">
                <div className="text-white items-center flex pl-2 pr-2 py-1.5 rounded-lg">
                  <CursorArrowRaysIcon className="w-6 mr-2" />
                  <div className="flex flex-col">
                    <p className=" text-bottom text-sm font-semibold">
                      Success
                    </p>
                    <p className=" text-bottom text-lg font-semibold">
                      {numberFormatWithoutCurr(data.success_count)}
                    </p>
                  </div>
                </div>
                <div className="text-white items-center flex pl-2 pr-2 py-1.5 rounded-lg">
                  <ExclamationCircleIcon className="w-6 mr-2" />
                  <div className="flex flex-col">
                    <p className=" text-bottom text-sm font-semibold">Failed</p>
                    <p className=" text-bottom text-lg font-semibold">
                      {numberFormatWithoutCurr(data.failed_count)}
                    </p>
                  </div>
                </div>
                <div className="text-white items-center flex pl-2 pr-2 py-1.5 rounded-lg">
                  <ClockIcon className="w-6 mr-2" />
                  <div className="flex flex-col">
                    <p className=" text-bottom text-sm font-semibold">
                      Pending
                    </p>
                    <p className=" text-bottom text-lg font-semibold">
                      {data.pending_count}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-x-4 pt-4 pb-4">
            <select
              onChange={(e) => {
                setMonth(e.target.value);
              }}
              className="select select-bordered w-full max-w-[150px] select-sm bg-gray-300 text-gray-600"
            >
              <option disabled>Select Month</option>
              {generateMonthArray().map((mnth) => {
                return (
                  <option
                    key={mnth.month}
                    value={mnth.month_value}
                    defaultValue=""
                    selected={month === mnth.month_value && "selected"}
                  >
                    {mnth.month}
                  </option>
                );
              })}
            </select>

            <select
              onChange={(e) => {
                setYear(e.target.value);
              }}
              className="select select-bordered w-full max-w-[150px] select-sm bg-gray-300 text-gray-600"
            >
              <option disabled>Select Year</option>
              {["2023", "2024", "2025"].map((yr, idx) => {
                return (
                  <option
                    key={idx}
                    value={yr}
                    selected={year == yr && "selected"}
                  >
                    {yr}
                  </option>
                );
              })}
            </select>

            <button
              onClick={() => {
                filterMonthYear(`${month}-${year}`);
                setRefresh(!refresh);
              }}
              className="btn btn-sm bg-primaryColor text-white hover:bg-primaryColor/80"
            >
              <FaFilter size={15} />
              Submit
            </button>
          </div>

          <div className={`flex flex-col lg:flex-row w-full gap-x-4`}>
            <div className="bg-gray-100 text-gray-700 flex flex-col rounded-xl pl-4 pr-4 pt-4 overflow-hidden w-full lg:w-1/3 ">
              <div className="flex items-center gap-x-2  ">
                <PiCoinsBold
                  size={25}
                  className="text-primaryColor rounded-full p-1.5 bg-primaryColor/10"
                />
                <h3 className="text-sm font-semibold"> Today Transaction</h3>
              </div>

              <div className="flex items-center text-[10px] mt-1">
                {comparisonRevenue < 0 ? (
                  <div className="flex items-center py-1 px-2 bg-rose-100 w-fit rounded-full mr-1 text-rose-600">
                    <HiArrowTrendingDown size={12} className="mr-1" />
                    {comparisonRevenue}%
                  </div>
                ) : (
                  <div className="flex items-center py-1 px-2 bg-emerald-100 w-fit rounded-full mr-1 text-emerald-600">
                    <HiArrowTrendingUp size={12} className="mr-1" />
                    {comparisonRevenue}%
                  </div>
                )}

                <p>vs {lastMonthComparison}</p>
              </div>
              <TodayRevenue revenue={revenue} loading={loading} />
            </div>

            {user && isAllowed([2]) && (
              <>
                <div
                  className={`flex flex-col text-gray-700 bg-gray-100 rounded-xl p-4 w-1/3 `}
                >
                  <div className="flex items-center gap-x-2 ">
                    <PiCoinsBold
                      size={25}
                      className="text-primaryColor rounded-full p-1.5 bg-primaryColor/10"
                    />
                    <h3 className="text-sm font-semibold">
                      {" "}
                      Total Transaction
                    </h3>
                  </div>
                  <h1 className="text-2xl mt-1 font-bold">
                    Rp. {numberFormatWithoutCurr(revenueByDate)}
                  </h1>

                  <div className="flex items-center text-[10px] mt-1">
                    {trxTrending < 0 ? (
                      <div className="flex items-center py-1 px-2 bg-rose-100 w-fit rounded-full mr-1 text-rose-600">
                        <HiArrowTrendingDown size={12} className="mr-1" />
                        {trxTrending}%
                      </div>
                    ) : (
                      <div className="flex items-center py-1 px-2 bg-emerald-100 w-fit rounded-full mr-1 text-emerald-600">
                        <HiArrowTrendingUp size={12} className="mr-1" />
                        {trxTrending}%
                      </div>
                    )}

                    <p>vs {lastMonthComparison}</p>
                  </div>

                  <TotalSales
                    data={data.revenue_transaction_success_monthly}
                    totalHit={false}
                    isAdmin={user?.role_id === 1}
                  />
                </div>
                <div className="bg-gray-100 text-gray-700 w-1/3 flex-col rounded-xl  pt-4 items-center">
                  <div className="flex items-start gap-x-4 w-full ml-4">
                    <ChartPieIcon className="w-6" />
                    <p className=" ">Top Products</p>
                  </div>
                  <div className="flex gap-x-4 text-gray-700 items-center bg-gray-100 rounded-xl pt-4">
                    <TopProducts data={products} loading={loading} />
                  </div>
                </div>
              </>
            )}

            {user && isAllowed([1]) && (
              <>
                <div
                  className={`flex flex-col text-gray-700 bg-gray-100 rounded-xl p-4 w-full lg:w-1/3`}
                >
                  <div className="flex items-center gap-x-2 ">
                    <PiCoinsBold
                      size={25}
                      className="text-primaryColor rounded-full p-1.5 bg-primaryColor/10"
                    />
                    <h3 className="text-sm font-semibold">
                      {" "}
                      Total Transaction
                    </h3>
                  </div>
                  <h1 className="text-2xl mt-1 font-bold">
                    Rp. {numberFormatWithoutCurr(revenueByDate)}
                  </h1>

                  <div className="flex items-center text-[10px] mt-1">
                    {trxTrending < 0 ? (
                      <div className="flex items-center py-1 px-2 bg-rose-100 w-fit rounded-full mr-1 text-rose-600">
                        <HiArrowTrendingDown size={12} className="mr-1" />
                        {trxTrending}%
                      </div>
                    ) : (
                      <div className="flex items-center py-1 px-2 bg-emerald-100 w-fit rounded-full mr-1 text-emerald-600">
                        <HiArrowTrendingUp size={12} className="mr-1" />
                        {trxTrending}%
                      </div>
                    )}

                    <p>vs {lastMonthComparison}</p>
                  </div>

                  <TotalSales
                    data={data.revenue_transaction_success_monthly}
                    totalHit={false}
                    isAdmin={user?.role_id === 1}
                  />
                </div>
                <div className="flex flex-col flex-grow bg-gray-100 rounded-xl text-gray-700 p-4 w-full lg:w-1/3">
                  <div className="flex items-center gap-x-2 text-gray-700">
                    <MdAdsClick
                      size={25}
                      className="text-primaryColor rounded-full p-1.5 bg-primaryColor/10"
                    />
                    <h3 className="text-sm font-semibold"> Total Hit</h3>
                  </div>
                  <h1 className="text-2xl mt-1  font-bold">
                    {data.monthly_hit_transaction}
                  </h1>

                  <div className="flex items-center text-[10px] mt-1">
                    {hitTrending < 0 ? (
                      <div className="flex items-center py-1 px-2 bg-rose-100 w-fit rounded-full mr-1 text-rose-600">
                        <HiArrowTrendingDown size={12} className="mr-1" />
                        {hitTrending}%
                      </div>
                    ) : (
                      <div className="flex items-center py-1 px-2 bg-emerald-100 w-fit rounded-full mr-1 text-emerald-600">
                        <HiArrowTrendingUp size={12} className="mr-1" />
                        {hitTrending}%
                      </div>
                    )}

                    <p>vs {lastMonthComparison}</p>
                  </div>

                  <TotalSales
                    data={data.revenue_transaction_success_monthly}
                    totalHit={true}
                    isAdmin={user?.role_id === 1}
                  />
                </div>
              </>
            )}
          </div>

          {isAllowed([1]) && (
            <div className="flex lg:flex-row flex-col gap-y-4 lg:gap-x-4">
              <div className="bg-gray-100 text-gray-700 w-full lg:w-1/2 flex-col rounded-xl pt-4 pl-4 items-center">
                <div className="flex items-center gap-x-2 ">
                  <PiCoinsBold
                    size={25}
                    className="text-primaryColor rounded-full p-1.5 bg-primaryColor/10"
                  />
                  <h3 className="text-sm font-semibold"> Total Margin</h3>
                </div>
                <h1 className="text-2xl mt-1 font-bold">
                  Rp. {numberFormatWithoutCurr(marginByDate)}
                </h1>

                <div className="flex items-center text-[10px] mt-1">
                  {comparisonMargin < 0 ? (
                    <div className="flex items-center py-1 px-2 bg-rose-100 w-fit rounded-full mr-1 text-rose-600">
                      <HiArrowTrendingDown size={12} className="mr-1" />
                      {comparisonMargin}%
                    </div>
                  ) : (
                    <div className="flex items-center py-1 px-2 bg-emerald-100 w-fit rounded-full mr-1 text-emerald-600">
                      <HiArrowTrendingUp size={12} className="mr-1" />
                      {comparisonMargin}%
                    </div>
                  )}

                  <p>vs {lastMonthComparison}</p>
                </div>

                <TotalMargin data={data.revenue_transaction_success_monthly} />
              </div>
              <div className="bg-gray-100 text-gray-700 w-full lg:w-1/2 flex-col rounded-xl  pt-4 items-center">
                <div className="flex items-start gap-x-4 w-full ml-4">
                  <ChartPieIcon className="w-6" />
                  <p className=" ">Top Products</p>
                </div>
                <div className="flex gap-x-4 text-gray-700 items-center bg-gray-100 rounded-xl pt-4">
                  <TopProducts data={products} />
                </div>
              </div>
              <div className="bg-gray-100 text-gray-700 w-full lg:w-1/2 flex-col rounded-xl  pt-4 items-center">
                <div className="flex items-start gap-x-2 pb-4 ml-4">
                  <BuildingStorefrontIcon className="w-6" />
                  <h3 className="text-sm font-semibold"> Top Resellers</h3>
                </div>
                <TopReseller data={resellers} />
              </div>
            </div>
          )}
          {isAllowed([1]) && (
            <div className="bg-gray-100 text-gray-700 w-full flex-col rounded-xl items-center p-4">
              <TopTransactionTime data={highTransactionTime} />
            </div>
          )}
          <DashboardTransaction
            data={data.revenue_transaction_success_daily}
            loading={loading}
            month={month}
            setMonth={setMonth}
            year={year}
            setYear={setYear}
            setRefresh={setRefresh}
            refresh={refresh}
            user={user}
          />
        </div>
      </div>
    </div>
  );
}
