import React from "react";
import TransactionSkeleton from "../../transaction/components/TransactionSkeleton";
import NoData from "../../../component/NoData";
import TransactionSummaryItem from "./TransactionSummaryItem";
import { isAllowed } from "../../../Utils";
export default function DashboardTransaction({ loading, data, month, user }) {
  const renderTransaction = () => {
    if (loading) {
      return <TransactionSkeleton />;
    } else if (!data || data.length === 0) {
      return <NoData />;
    } else
      return data.map((transaction) => {
        return (
          <TransactionSummaryItem
            transaction={transaction}
            month={month}
            user={user}
          />
        );
      });
  };
  return (
    <div className="flex flex-col gap-y-4 mt-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-3 flex-grow">
          <h1 className="font-bold text-xl text-primaryColor">Daily Summary</h1>
        </div>
        <button className="rounded-full px-6 py-2 text-primaryColor border border-primaryColor text-xs font-semibold hover:text-white hover:bg-primaryColor click:scale-90 duration-200">
          See Details
        </button>
      </div>
      <div className="relative overflow-x-auto  rounded-lg border border-gray-200 ">
        <table className=" min-w-full text-sm text-left table-auto rtl:text-right text-gray-500 ">
          <thead className="text-xs text-gray-700  bg-gray-100">
            <tr key={(Math.random() * 100).toFixed(0)}>
              <th scope="col" className="px-6 py-3">
                Transaction Date
              </th>
              <th scope="col" className="px-6 py-3">
                Total Transaction
              </th>
              <th scope="col" className="px-6 py-3">
                Total Hit
              </th>
              {isAllowed([1], user) && (
                <th scope="col" className="px-6 py-3">
                  Margin
                </th>
              )}

              {isAllowed([1], user) && (
                <th scope="col" className="px-6 py-3">
                  % Margin
                </th>
              )}
            </tr>
          </thead>
          <tbody>{renderTransaction()}</tbody>
        </table>
      </div>
    </div>
  );
}
