import {
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import React from "react";
import ActionButton from "./ActionButton";
import { format } from "date-fns";

export default function TransactionItem({ currentItems: transaction }) {
  return (
    <tr
      key={transaction.code}
      class="bg-white border-b  hover:bg-gray-50 even:bg-gray-100 "
    >
      <td className="px-6 py-4">{transaction["code"]}</td>
      <td className="px-6 py-4">{transaction["reseller_code"]}</td>
      <td className="px-6 py-4">{transaction["product_code"]}</td>
      <td className="px-6 py-4">{transaction["identifier_number"]}</td>

      <td className="px-6 py-4">
        {transaction.status === "success" ? (
          <div className="flex items-center gap-x-2 px-4 py-2 text-white bg-emerald-500 rounded-lg w-fit">
            <CheckCircleIcon className="w-4 " />
            Sukses
          </div>
        ) : transaction.status === "failed" ? (
          <div className="flex items-center gap-x-2 px-4 py-2 text-white bg-rose-500 rounded-lg w-fit">
            <XCircleIcon className="w-4 " />
            Gagal
          </div>
        ) : (
          <div className="flex items-center gap-x-2 px-4 py-2 text-white bg-gray-500 rounded-lg w-fit">
            <ClockIcon className="w-4 " />
            Pending
          </div>
        )}
      </td>

      <td className="px-6 py-4">
        {format(new Date(transaction["date_entry"]), "dd MMM yyyy  HH:mm")}
      </td>

      <td className="px-6 py-4  ">
        <p className="whitespace-normal break-words text-wrap w-96">
          {transaction["message"]}
        </p>
      </td>
      {/* <td class="px-6 py-4">
        <ActionButton />
      </td> */}
    </tr>
  );
}
