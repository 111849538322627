import {
  ArrowLeftEndOnRectangleIcon,
  ArrowTrendingUpIcon,
  ChartPieIcon,
  CurrencyDollarIcon,
  UserCircleIcon,
  Bars3Icon,
} from "@heroicons/react/24/solid";
import React from "react";
import useLogout from "../../../hooks/useLogout";
import { RiDashboardFill } from "react-icons/ri";
import { NavLink, useLocation } from "react-router-dom";
import { PiCoinsBold } from "react-icons/pi";
import { HiBanknotes } from "react-icons/hi2";
import { TbPasswordFingerprint } from "react-icons/tb";

export default function DashboardSidebar() {
  const { logout } = useLogout();
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <div className="hidden md:flex flex-col px-4 py-8 bg-white w-[280px] h-full gap-y-3 font-semibold ">
      {/* <Bars3Icon size={25} /> */}
      <NavLink
        // className="flex items-center text-gray-400 gap-x-4 hover:bg-slate-100 rounded-full px-4 py-2"
        className={`flex items-center text-gray-400 gap-x-4 hover:bg-slate-100 rounded-full px-4 py-2 ${
          isActive("/dashboard") || isActive("/dashboard-today")
            ? "text-gray-800 "
            : ""
        }`}
        to="/dashboard"
      >
        <RiDashboardFill
          size={25}
          className={` ${
            isActive("/dashboard") || isActive("/dashboard-today")
              ? "text-primaryColor"
              : ""
          }`}
        />
        <p className=" ">Dashboard</p>
      </NavLink>
      <NavLink
        className={`flex items-center text-gray-400 gap-x-4 hover:bg-slate-100 rounded-full px-4 py-2 ${
          isActive("/transaction") ? "text-gray-800 " : ""
        }`}
        to="/transaction"
      >
        <PiCoinsBold
          size={25}
          className={` ${isActive("/transaction") ? "text-primaryColor" : ""}`}
        />
        <p className=" ">Transaction</p>
      </NavLink>
      <NavLink
        className={`flex items-center text-gray-400 gap-x-4 hover:bg-slate-100 rounded-full px-4 py-2 ${
          isActive("/deposit_request") ? "text-gray-800 " : ""
        }`}
        to="/deposit_request"
      >
        <HiBanknotes
          size={25}
          className={` ${
            isActive("/deposit_request") ? "text-primaryColor" : ""
          }`}
        />
        <p className=" ">Deposit Request</p>
      </NavLink>

      <div className="divider my-1 px-4" />
      <p className="px-4 -mt-2 mb-2 text-xs">ACCOUNT</p>
      <NavLink
        to="/change_password"
        className={`flex items-center text-gray-400 gap-x-4 hover:bg-slate-100 rounded-full px-4 py-2 ${
          isActive("/change_password") ? "text-gray-800 " : ""
        }`}
      >
        <TbPasswordFingerprint
          size={25}
          className={` ${
            isActive("/change_password") ? "text-primaryColor" : ""
          }`}
        />
        <p className=" ">Change Password</p>
      </NavLink>
      <button
        onClick={(e) => {
          logout();
        }}
        className="flex items-center text-gray-400 gap-x-4 hover:bg-slate-100 rounded-full px-4 py-2"
      >
        <ArrowLeftEndOnRectangleIcon className="w-6" />
        <p className=" ">Log Out</p>
      </button>
    </div>
  );
}
