import { useState } from "react";
import axiosRequest from "../api/AxiosRequest";
import toast from "react-hot-toast";

const useChangePassword = () => {
  const [loading, setLoading] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const changePassword = async () => {
    if (
      oldPassword === "" ||
      newPassword === "" ||
      confirmPassword === "" ||
      newPassword !== confirmPassword
    ) {
      toast.error("Password salah");
      return;
    }

    try {
      setLoading(true);
      const response = await axiosRequest.post(
        "/password/change",
        { old_password: oldPassword, new_password: newPassword },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("jwt"),
          },
        }
      );
      toast.success("Password anda telah berhasil dirubah");
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.status.message || "Terjadi kesalahan");
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    oldPassword,
    newPassword,
    confirmPassword,
    setOldPassword,
    setNewPassword,
    setConfirmPassword,
    changePassword,
  };
};

export default useChangePassword;
