import React from "react";
import DashboardSidebar from "../dashboard/components/DashboardSidebar";
import useChangePassword from "../../hooks/useChangePassword";
import { ArrowPathIcon } from "@heroicons/react/24/solid";

export default function ChangePassword() {
  const {
    loading,
    oldPassword,
    newPassword,
    confirmPassword,
    setOldPassword,
    setNewPassword,
    setConfirmPassword,
    changePassword,
  } = useChangePassword();
  return (
    <div className="relative  font-poppins h-screen">
      <div className="bg-transparent flex w-full h-full">
        <DashboardSidebar />

        <div className="flex flex-col flex-grow bg-white h-fit gap-y-4 max-w-full mx-auto p-6">
          <p className="text-xl text-gray-700 font-semibold">Change Password</p>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              changePassword();
            }}
            className="flex items-center gap-x-6 flex-wrap p-4 min-w-[400px] w-fit rounded-xl"
          >
            <div className="w-fit mb-6">
              <label class="flex text-sm font-semibold leading-6 text-gray-900">
                Old Password :
              </label>
              <div class="mt-2.5">
                <input
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  type="password"
                  placeholder="Old Password"
                  class="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="w-fit mb-6">
              <label class="flex text-sm font-semibold leading-6 text-gray-900">
                New Password :
              </label>
              <div class="mt-2.5">
                <input
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  type="password"
                  placeholder="New Password"
                  class="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="w-fit mb-6">
              <label class="flex text-sm font-semibold leading-6 text-gray-900">
                Confirm Password :
              </label>
              <div class="mt-2.5">
                <input
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  type="password"
                  placeholder="Confirm Password"
                  class="block w-full rounded-md border-0 px-3.5 py-2 bg-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryColor focus:outline-none duration-300 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="rounded-full h-fit bg-primaryColor text-white px-4 py-1.5"
            >
              {loading ? (
                <ArrowPathIcon className="w-4 animate-spin text-white" />
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
