import React from "react";

export default function NotFound() {
  return (
    <div className="w-full h-screen flex  justify-center bg-white font-poppins">
      <div className="flex flex-col items-center gap-y-4">
        <img className="max-w-[400px] " src="/404.png" />
        <h1 className="text-3xl text-primaryColor font-bold">Page Not Found</h1>
        <p className="text-gray-400 max-w-[400px] text-center">
          We're Sorry, the page you requested could not be found Please go back
          to the homepage.
        </p>

        <a
          href="/"
          className="px-6 py-2 bg-primaryColor text-white text-lg mt-4 rounded-full"
        >
          Go Home
        </a>
      </div>
    </div>
  );
}
