import React, { useState } from "react";
import axiosRequest from "../api/AxiosRequest";
import toast from "react-hot-toast";

const useApprove = () => {
  const [levelCode, setLevelCode] = useState(null);
  const [markup, setMarkup] = useState(0);

  const [loading, setLoading] = useState(false);

  const submitApproval = async (idReseller, isApprove) => {
    const param = {
      code_level: levelCode,
      markup: markup,
    };
    const approve = isApprove ? "approved" : "rejected";

    setLoading(true);
    try {
      const response = await axiosRequest.put(
        "/api/v1/resellers/" + idReseller + "/" + approve,
        param,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("jwt"),
          },
        }
      );

      toast.success("Approval Berhasil");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    levelCode,
    setLevelCode,
    markup,
    setMarkup,
    submitApproval,
    loading,
  };
};

export default useApprove;
