import { Route, Routes } from "react-router-dom";
import Navbar from "./component/Navbar";
import Documentation from "./pages/integration/Documentation";
import Registration from "./pages/registration/Registration";
import RegistrationSuccess from "./pages/registration/RegistrationSuccess";
import Login from "./pages/login/Login";
import Approval from "./pages/approval/Approval";
import Transaction from "./pages/transaction/Transaction";
import ApprovalDetail from "./pages/approval/ApprovalDetail";
import Home from "./pages/home/Home";
import { Toaster } from "react-hot-toast";
import NotFound from "./pages/errorPage/NotFound";
import Unauthorized from "./pages/errorPage/Unauthorized";
import ProtectedRoute from "./route/ProtectedRoute";
import Deposit from "./pages/deposit/Deposit";
import Welcome from "./pages/dashboard/Welcome";
import ChangePassword from "./pages/account/ChangePassword";
import ForgotPassword from "./pages/account/ForgotPassword";
import DashboardToday from "./pages/dashboard/DashboardToday";

function App() {
  return (
    <div className="flex flex-col w-full h-screen relative bg-white">
      <Navbar />
      <Toaster />
      <Routes>
        {/* <Route path="/" element={<PriceList />} /> */}
        <Route path="/integration" element={<Documentation />} />
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute roles={[1, 2]}>
              <Welcome />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard-today"
          element={
            <ProtectedRoute roles={[1, 2]}>
              <DashboardToday />
            </ProtectedRoute>
          }
        />

        <Route
          path="/approval"
          element={
            <ProtectedRoute roles={[1]}>
              <Approval />
            </ProtectedRoute>
          }
        />

        <Route
          path="/transaction"
          element={
            <ProtectedRoute roles={[1, 2]}>
              <Transaction />
            </ProtectedRoute>
          }
        />

        <Route path="/pendaftaran" element={<Registration />} />
        <Route path="/successPage" element={<RegistrationSuccess />} />

        <Route path="/approvalDetail" element={<ApprovalDetail />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route
          path="/deposit_request"
          element={
            <ProtectedRoute roles={[2]}>
              <Deposit />
            </ProtectedRoute>
          }
        />

        <Route
          path="/change_password"
          element={
            <ProtectedRoute roles={[1, 2]}>
              <ChangePassword />
            </ProtectedRoute>
          }
        />

        <Route path="*" element={<NotFound />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
    </div>
  );
}

export default App;
