// src/context/UserContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import toast from "react-hot-toast";
import axiosRequest from "../api/AxiosRequest";

// Create the UserContext with default value as null
const UserContext = createContext(null);

// Create a custom hook to use the UserContext
export const useUser = () => useContext(UserContext);

// Create the provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // Retrieve user from local storage if it exists
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [balance, setBalance] = useState(0);

  useEffect(() => {
    //get user balance
    const getUserBalance = async () => {
      try {
        const response = await axiosRequest.get("/my/saldo", {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("jwt"),
          },
        });

        setBalance(response.data.data ? response.data.data.saldo : 0);
      } catch (error) {
        toast.error(error.message);
      }
    };

    // Save user to local storage whenever it changes
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
      getUserBalance();
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser, balance }}>
      {children}
    </UserContext.Provider>
  );
};
