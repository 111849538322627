import { configureStore } from "@reduxjs/toolkit";

import dashboardSlice from "./dashboard/dashboard-slice";

const store = configureStore({
  reducer: {
    dashboard: dashboardSlice.reducer,
  },
});

export default store;
