import React from "react";

function WhyUs(props) {
  const stats = [
    { name: "Transaksi  hitungan detik", value: "< 10S" },
    { name: "Produk beragam", value: "300+" },
    { name: "Customer Service Support", value: "17/7" },
  ];
  return (
    <div className="w-full  bg-primaryColor relative isolate overflow-hidden text-white mt-6  py-24 sm:py-32">
      <div className="mx-auto max-w-5xl px-6 lg:px-8 flex flex-col items-center">
        <div className="mx-auto max-w-3xl lg:mx-0 flex flex-col items-center">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Mengapa Kami
          </h2>
          <p className="mt-6 text-lg leading-8 text-center text-gray-300">
            Kami yakin produk dan jasa yang kami berikan adalah yang terbaik.
            Nikmati beberapa fitur unggulan kami, bila anda mendaftar menjadi
            pembeli kami :
          </p>
        </div>
        <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
          <dl className="mt-12 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-3">
            {stats.map((stat) => (
              <div
                key={stat.name}
                className="flex flex-col-reverse items-center"
              >
                <dt className="text-base leading-7 text-gray-300  text-center">
                  {stat.name}
                </dt>
                <dd className="text-4xl font-bold leading-9 tracking-tight text-white">
                  {stat.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
