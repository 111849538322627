import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axiosRequest from "../api/AxiosRequest";
import { formatNumber } from "../Utils";
import { getDate, getComparisonMonth } from "../Utils";
import { useLocation } from "react-router-dom";

const useGetDashboard = () => {
  const [loading, setLoading] = useState();
  const [month, setMonth] = useState(
    (new Date().getMonth() + 1).toString().padStart(2, "0")
  );
  const [year, setYear] = useState(new Date().getFullYear());

  const lastMonthComparison = getComparisonMonth();

  const [refresh, setRefresh] = useState(false);

  const [data, setData] = useState({
    amount_transaction_succes: 0,
    success_count: 0,
    failed_count: 0,
    pending_count: 0,
    monthly_amount_transaction: 0,
    monthly_hit_transaction: 0,
    revenue_transaction_success_monthly: [],
    revenue_transaction_success_daily: [],
  });

  const [trxTrending, setTrxTrending] = useState(0);
  const [hitTrending, setHitTrending] = useState(0);

  const [comparisonRevenue, setComparisonRevenue] = useState(0);
  const [comparisonRevenueToday, setComparisonRevenueToday] = useState(0);
  const [comparisonMargin, setComparisonMargin] = useState(0);

  const [revenue, setRevenue] = useState();
  const [revenueToday, setRevenueToday] = useState();

  const [revenueByDate, setRevenueByDate] = useState(0);
  const [marginByDate, setMarginByDate] = useState(0);

  const location = useLocation();
  const isMonthly = () => {
    return location.pathname === "/dashboard";
  };

  const setDataMargin = (data) => {
    const revArray = isMonthly()
      ? data.revenue_transaction_success_monthly
      : data.revenue_transaction_success_daily.length < 8
      ? data.revenue_transaction_success_daily.slice(0, data.length)
      : data.revenue_transaction_success_daily.slice(0, 8);

    const today = new Date();
    const marginByDate = revArray.find((item) => {
      return item.date.substring(0, 7) === `${year}-${month}`;
    }).margin;
    setMarginByDate(marginByDate);

    if (isMonthly()) {
      const comparisonLastMargin =
        revArray.length > 1
          ? revArray[revArray.findLastIndex((item) => item) - 1].margin
          : 0;
      const comparison = (
        ((marginByDate - comparisonLastMargin) / comparisonLastMargin) *
        100
      ).toFixed(2);

      setComparisonMargin(comparison);
    } else {
      const comparisonLastMargin = revArray.length > 1 ? revArray[1].margin : 0;
      const comparison = (
        ((marginByDate - comparisonLastMargin) / comparisonLastMargin) *
        100
      ).toFixed(2);

      setComparisonMargin(comparison);
    }
  };

  const setRevenueData = (data) => {
    const revenueTransactionsMonthly = data.revenue_transaction_success_monthly;

    const today = new Date();
    const dayOfMonth = `${year}-${month}` === getDate() ? today.getDate() : 30;

    const revenueByDate = revenueTransactionsMonthly.find((item) => {
      return item.date.substring(0, 7) === `${year}-${month}`;
    }).revenue;
    setRevenueByDate(revenueByDate);

    const comparisonLastRevenue =
      revenueTransactionsMonthly[
        revenueTransactionsMonthly.findLastIndex((item) => item) - 1
      ].revenue;

    const comparison = (
      ((revenueByDate - comparisonLastRevenue) / comparisonLastRevenue) *
      100
    ).toFixed(2);

    setComparisonRevenue(comparison);

    setRevenue({
      options: {
        series: [Math.floor(comparison) + 100],
        chart: {
          type: "radialBar",
          dataLabels: {
            enabled: false,
            show: false,
          },
          sparkline: { enabled: true },
        },

        plotOptions: {
          radialBar: {
            hollow: {
              size: "45%",
            },
            track: {
              show: true,
              background: "#d3d3d3",
            },
            offsetY: 0,
            offsetX: 0,
            dataLabels: {
              show: true,
              name: {
                show: true,
                fontSize: "12px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#575757",
                offsetY: -4,
                formatter: function (val) {
                  return "Total";
                },
              },
              value: {
                show: true,
                fontSize: "12px",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#2c3364",
                offsetY: 4,
                formatter: function () {
                  return `${formatNumber(revenueByDate)} / ${formatNumber(
                    comparisonLastRevenue
                  )}`;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        fill: {
          colors: ["#2c3364"],
          opacity: 1,
        },
      },
    });
  };

  const setRevenueDataToday = (data) => {
    const revenueTransactionsDaily = data.revenue_transaction_success_daily;

    if (revenueTransactionsDaily.length > 0) {
      const revenueTransactionToday = revenueTransactionsDaily[0].revenue;

      if (revenueTransactionsDaily.length > 2) {
        const comparisonLastRevenue = revenueTransactionsDaily[1].revenue;
        const comparison = (
          ((revenueTransactionToday - comparisonLastRevenue) /
            comparisonLastRevenue) *
          100
        ).toFixed(2);

        setComparisonRevenueToday(comparison);
        setRevenueToday({
          options: {
            series: [Math.floor(comparison) + 100],
            chart: {
              type: "radialBar",
              dataLabels: {
                enabled: false,
                show: false,
              },
              sparkline: { enabled: true },
            },

            plotOptions: {
              radialBar: {
                hollow: {
                  size: "45%",
                },
                track: {
                  show: true,
                  background: "#d3d3d3",
                },
                offsetY: 0,
                offsetX: 0,
                dataLabels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: "12px",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 600,
                    color: "#575757",
                    offsetY: -4,
                    formatter: function (val) {
                      return "Total";
                    },
                  },
                  value: {
                    show: true,
                    fontSize: "12px",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 600,
                    color: "#2c3364",
                    offsetY: 4,
                    formatter: function () {
                      return `${formatNumber(
                        revenueTransactionToday
                      )} / ${formatNumber(comparisonLastRevenue)}`;
                    },
                  },
                },
              },
            },
            stroke: {
              lineCap: "round",
            },
            fill: {
              colors: ["#2c3364"],
              opacity: 1,
            },
          },
        });
      }
    }
    return 0;
  };

  // const getComparisonRevenueMontlhy = (data) => {
  //   if (data.revenue_transaction_success_monthly.length > 0) {
  //     const revenueTransactionsMonthly =
  //       data.revenue_transaction_success_monthly;
  //     const revenueLastTransaction =
  //       revenueTransactionsMonthly[
  //         revenueTransactionsMonthly.findLastIndex((item) => item)
  //       ].revenue;
  //     const comparisonLastRevenue =
  //       revenueTransactionsMonthly[
  //         revenueTransactionsMonthly.findLastIndex((item) => item) - 1
  //       ].revenue;

  //     setRevenueMonthly(revenueLastTransaction);
  //     setLastRevenueMonthly(comparisonLastRevenue);

  //     const comparison = (
  //       ((revenueLastTransaction - comparisonLastRevenue) /
  //         comparisonLastRevenue) *
  //       100
  //     ).toFixed(2);
  //     return comparison;
  //   } else {
  //     return 0;
  //   }
  // };

  const getTrending = (data) => {
    const revArray = data.revenue_transaction_success_monthly;
    const today = new Date();
    const dayOfMonth = `${year}-${month}` === getDate() ? today.getDate() : 30;

    if (revArray && revArray.length > 0) {
      const avgThisMonth =
        revArray.find((item) => {
          return item.date.substring(0, 7) === `${year}-${month}`;
        }).revenue / dayOfMonth;
      const avgLastMonth =
        revArray[revArray.findLastIndex((item) => item) - 1].revenue / 30;

      const trending = (
        ((avgThisMonth - avgLastMonth) / avgLastMonth) *
        100
      ).toFixed(2);

      const avgHitThisMonth =
        revArray.find((item) => {
          return item.date.substring(0, 7) === `${year}-${month}`;
        }).total_transaction / dayOfMonth;
      const avgHitLastMonth =
        revArray[revArray.findLastIndex((item) => item) - 1].total_transaction /
        30;
      const trendingHit = (
        ((avgHitThisMonth - avgHitLastMonth) / avgHitLastMonth) *
        100
      ).toFixed(2);
      setTrxTrending(trending);
      setHitTrending(trendingHit);
    }

    data.monthly_hit_transaction = revArray.find((item) => {
      return item.date.substring(0, 7) === `${year}-${month}`;
    }).total_transaction;
  };

  useEffect(() => {
    const getDashboardData = async () => {
      setLoading(true);
      try {
        const response = await axiosRequest.get(
          "/api/v2/dashboard/summary_transactions_today",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("jwt"),
            },
            params: {
              month: `${month}-${year}`,
            },
          }
        );
        setData(response.data.data);
        getTrending(response.data.data);
        setRevenueData(response.data.data);
        setRevenueDataToday(response.data.data);
        setDataMargin(response.data.data);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    getDashboardData();
  }, [refresh]);

  return {
    loading,
    data,
    trxTrending,
    hitTrending,
    refresh,
    setRefresh,
    month,
    setMonth,
    year,
    setYear,
    revenue,
    revenueToday,
    comparisonRevenue,
    comparisonRevenueToday,
    revenueByDate,
    lastMonthComparison,
    marginByDate,
    comparisonMargin,
  };
};

export default useGetDashboard;
