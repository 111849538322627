import { ClipboardDocumentIcon } from "@heroicons/react/16/solid";
import { CodeBracketSquareIcon } from "@heroicons/react/20/solid";
import { DocumentIcon } from "@heroicons/react/24/outline";
import React from "react";

export default function Documentation() {
  return (
    <div className="relative bg-gray-100 font-poppins pb-12">
      <div className="bg-primaryColor w-full h-[125px]"></div>

      <div className="bg-transparent flex justify-center w-full gap-x-4 -mt-20 ">
        <div className="flex flex-col px-8 pb-16 rounded-lg bg-white max-w-4xl w-[850px] h-fit gap-y-4   ">
          <div className="border-b border-dashed border-gray-200 h-20 flex justify-between items-center">
            <p className="text-2xl "> Documentation</p>
            <CodeBracketSquareIcon className="w-12 text-amber-400" />
          </div>
          <h2 className="text-xl font-bold text-primaryColor">
            PENDAFTARAN TRANSAKSI IP
          </h2>
          <h3 className="text-sm">
            Jika Pada Saat Pendaftaran Belum Mendaftarkan{" "}
            <span className="font-[600] text-lg text-primaryColor">
              IP PUBLIC
            </span>
            , &nbsp;
            <span className="font-[600] text-lg text-primaryColor">
              URL REPORT
            </span>
            &nbsp; dan&nbsp;
            <span className="font-[600] text-lg text-primaryColor">
              SETTING PASSWORD
            </span>
            &nbsp;&nbsp;Silahkan Ikuti Langkah Di Bawah Ini :
          </h3>
          <p>A. Mendaftarkan IP Address</p>
          <div className="flex flex-col rounded-xl bg-gray-100 p-4 gap-y-4">
            <p>
              Untuk mendaftarkan IP Transaksi anda, harus menggunakan IP Static
              dengan format &nbsp;&nbsp;
              <span className="font-[600] text-lg text-primaryColor">
                IPADDRESS*[newip]*[pin]
              </span>
              &nbsp; Contoh :
            </p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              IPADDRESS*123.456.67.89*1234
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>
          </div>
          <p>B. Mendaftarkan URL Report</p>
          <div className="flex flex-col rounded-xl bg-gray-100 p-4 gap-y-4">
            <p>
              URL Report akan berfungsi untuk menerima report status transaksi
              sukses atau gagal. Format pendaftaran url report : &nbsp;
              <span className="font-[600] text-lg text-primaryColor">
                REPORTURL*[newurl]*[pin]
              </span>
              &nbsp; Contoh :
            </p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              REPORTURL*http://123.456.67.89:6969/*1234
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>
          </div>
          <p>C. Setting Password </p>
          <div className="flex flex-col rounded-xl bg-gray-100 p-4 gap-y-4">
            <p>
              Password ini hanya digunakan untuk transaksi IP Setting Password :
              Contoh :&nbsp;
              <span className="font-[600] text-lg text-primaryColor">
                PASSIP*[oldpassword]*[newpassword]*[pin]
              </span>
              &nbsp; Contoh :
            </p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              PASSIP*test1122*test1234*1234
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>
          </div>
          <p>D. Semua Format Di Kirim Via Jabber yg sudah terdaftar</p>
          <br className="my-2" />
          <h2 className="text-xl font-bold text-primaryColor">
            FORMAT TRANSAKSI IP KHUSUS PENGGUNA OTOMAX
          </h2>
          <p>1. CARA SETTING DI MODUL OTOMAX</p>
          <h3>2. Transaksi Dengan Method HTTP GET</h3>
          <div className="flex flex-col rounded-xl bg-gray-100 p-4 gap-y-4">
            <p>
              A. KIRIM KE IP CENTER :
              <span className="font-[600] text-lg text-primaryColor">
                &nbsp; http://103.153.248.164:6969/
              </span>
            </p>
            <p>
              PARAMETER PARSING :
              <span className="font-[600] text-lg text-primaryColor">
                pin=[pin]|password=[password]|memberid=[memberid]
              </span>
            </p>
          </div>
          <p> B. FORMAT CEK SALDO</p>
          <div className="flex flex-col rounded-xl bg-gray-100 p-4 gap-y-4">
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>
                http://103.153.248.164:6969/balance?memberID=[memberid]&password=[password]&pin=[pin]
              </p>
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>
          </div>
          <p>C. FORMAT TRANSAKSI</p>
          <div className="flex flex-col rounded-xl bg-gray-100 p-4 gap-y-4">
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>
                http://103.153.248.164:6969/trx?product=[product]&qty=[qty]&dest=[tujuan]&refID=[trxid]&memberID=
                [memberid]&sign=[sign]
              </p>
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>
          </div>
          <p>D. FORMAT REQ TIKET</p>
          <div className="flex flex-col rounded-xl bg-gray-100 p-4 gap-y-4">
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>
                http://103.153.248.164:6969/ticket?memberID=[memberid]&sign=[sign]&amount=10001000
              </p>
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>
          </div>
          <br className="my-2" />
          <h2 className="text-xl font-bold text-primaryColor">
            FORMAT TRANSAKSI IP SELAIN SOFTWARE OTOMAX
          </h2>
          <p>TRANSAKSI DENGAN FORMAT HTTP GET/POST</p>
          <div className="flex flex-col rounded-xl bg-gray-100 p-4 gap-y-4">
            <p>KIRIM KE IP CENTER :</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              http://103.153.248.164:6969/
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>
            <p>FORMAT CEK SALDO :</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              http://103.153.248.164:6969/balance?memberID=[memberid]&password=[password]&pin=[pin]
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>
            <p>FORMAT TRANSAKSI :</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              http://103.153.248.164:6969/trx?product=[product]&qty=[qty]&dest=[tujuan]&refID=[trx]&memberID=[memberid]
              &sign=[sign]
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>
            <p>FORMAT REQ TIKET :</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              http://103.153.248.164:6969/ticket?memberID=[memberid]&sign=[sign]&amount=1001000
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>
          </div>
          <br className="my-2" />
          <h2 className="text-xl font-bold text-primaryColor">
            FORMAT TRANSAKSI JABBER ATAU GTALK
          </h2>
          <div className="flex flex-col rounded-xl bg-gray-100 p-4 gap-y-4">
            <p>WHATSAPP CENTER</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>http://wa.me/6281510159688</p>
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>

            <p>JABBER CENTER</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>balipulsareload@jabb.im </p>

              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>

            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>bpr_center@jabb.im</p>
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>
          </div>
          <div className="flex flex-col rounded-xl bg-gray-100 p-4 gap-y-4">
            <p>Pengisian pulsa (trxid) :</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>[kodeproduk].[tujuan].[pin].R#[trxid]</p>
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>

            <p>Pengisian pulsa (tanpa trxid) :</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>[kodeproduk].[counter].[tujuan].[pin]</p>
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>

            <p>Cek Saldo :</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>S.[pin]</p>
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>

            <p>Format ambil Tiket :</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>TIKET.[nominal].[pin]</p>
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>

            <p>Cek Harga Produk :</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>CH.[kodeproduk]</p>
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>

            <p>Tambah/Pararel Jabber :</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>TGT*ID_Jabber*Pin</p>
              <button>
                <DocumentIcon className="w-5 text-white" />
              </button>
            </div>
          </div>
          <br className="my-2" />

          <h2 className="text-xl font-bold text-primaryColor">
            RESPON TRANSAKSI
          </h2>
          <div className="flex flex-col rounded-xl bg-gray-100 p-4 gap-y-4">
            <p>NOTE : FORMAT TRANSAKSI WAJIB PAKE TRXID</p>

            <p>RESPON SUKSES :</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>
                R#240414180154645667 Isi ITP10.081549078741 Harga: 9.735 SUKSES.
                SN: 0001146101713092517574877 . Saldo Awal 31.604.706 Akhir
                31.594.971 .@ 14/04 18.02.09
              </p>
            </div>

            <p>RESPON PROSES :</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>
                R#dfmxvy6f0aka6712 Isi ITP10.085706634406 akan diproses @13.02.
                Saldo 11.080.092 - 9.735 = 11.070.357
              </p>
            </div>

            <p>RESPON GAGAL :</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>
                R#dfb4vy8a0auj8f20 Isi ITP10.085766754940 GAGAL. , Saldo
                11.070.357 .@ 17/04 13.02.24
              </p>
            </div>

            <p>RESPON TUJUAN SALAH :</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>
                TRANSFER INDOSAT 10K ITP10.0857260606998 GAGAL. Nomor tujuan
                salah. Saldo 14.878.513 @12.19
              </p>
            </div>

            <p>RESPON SALDO TIDAK CUKUP :</p>
            <div className="flex justify-between bg-primaryColor text-white/80 italic rounded-lg w-full p-4">
              <p>
                'ITP10.085738362400.1234’ GAGAL. Saldo tidak cukup. Hrg 10.020,
                Saldo 5.175 Limit 0, proses 0. Ketik TIKET utk deposit
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
