import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axiosRequest from "../api/AxiosRequest";

const useGetDeposit = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  useEffect(() => {
    const getTickets = async () => {
      setLoading(true);
      try {
        const response = await axiosRequest.get("/api/v1/deposits", {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("jwt"),
          },
        });

        setTickets(response.data.data);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    getTickets();
  }, [refreshTrigger]);

  return { tickets, loading, setRefreshTrigger, refreshTrigger };
};

export default useGetDeposit;
