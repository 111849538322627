import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import NoData from "../../../component/NoData";
import { numberFormatWithoutCurr } from "../../../Utils";
import { useSelector } from "react-redux";
export default function TopReseller({ data }) {
  const [sellerName, setSellerName] = useState([]);
  const [sellerTrx, setSellerTrx] = useState([]);

  const loading = useSelector((state) => state.dashboard.loading);

  useEffect(() => {
    if (data) {
      const temp = data.slice(0, 5);

      setSellerName(
        temp.map(
          (item) => `${item.name} - ${numberFormatWithoutCurr(item.amount)}`
        )
      );
      setSellerTrx(temp.map((item) => Number(item.count)));
    }
  }, [data]);

  var options = {
    series: sellerTrx,
    labels: sellerName,
    chart: {
      fontFamily: "Helvetica, Arial, sans-serif",
    },

    legend: {
      show: true,
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "14px",
      fontFamily: "Poppins, sans-serif",
      fontWeight: 400,
      width: undefined,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      markers: {
        size: 8,
        shape: "circle", // circle, square, line, plus, cross
        strokeWidth: 2,
        fillColors: undefined,
        radius: 2,

        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 2,
      },

      onItemHover: {
        highlightDataSeries: true,
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "round",
      width: 12,
      dashArray: 6,
      colors: ["#F3F4F6"],
    },
    colors: [
      "#2c3364dd",
      "#2E236Cdd",
      "#433D8Bdd",
      "#C8ACD6dd",
      "#433D8Bdd",
      "#698b63",
      "#709490",
      "#9e81a1",
      "#aa8e94",
      "#b39ba0",
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "20%",
        },
      },
    },

    dataLabels: {
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 4,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: "#eeeeee",
        opacity: 0.4,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      enabled: true,
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "12px",
        fontFamily: "Poppins, sans-serif",
        fontWeight: "bold",
        colors: ["#fff"],
      },
    },
  };

  const loadingState = () => {
    return (
      <div className="flex gap-x-4 items-center pb-4 justify-between w-full">
        <div className="animate-pulse w-[150px] h-[150px] mx-auto rounded-full bg-gray-300"></div>
      </div>
    );
  };

  if (loading) {
    return loadingState();
  } else if (!data || data.length === 0) {
    return <NoData />;
  } else {
    return (
      <div className="flex w-full justify-center h-full">
        <Chart
          options={options}
          series={options.series}
          className="mx-auto"
          type="donut"
          width="420"
          height="420"
        />
      </div>
    );
  }
}
