import React from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";

const useLogout = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const logout = () => {
    localStorage.removeItem("jwt");
    setUser(null);
    navigate("/");
  };

  return { logout };
};

export default useLogout;
