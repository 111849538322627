import { ArrowLeftEndOnRectangleIcon } from "@heroicons/react/24/solid";
import useLogout from "../../../hooks/useLogout";
import { RiDashboardFill } from "react-icons/ri";
import { NavLink, useLocation } from "react-router-dom";
import { PiCoinsBold } from "react-icons/pi";
import { HiBanknotes } from "react-icons/hi2";
import { TbPasswordFingerprint } from "react-icons/tb";

export function DashboardDrawer({ isDrawerOpen, closeDrawer }) {
  const { logout } = useLogout();
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <div
      className={`${
        isDrawerOpen ? "flex flex-col md:hidden" : "hidden"
      } px-4 py-8 bg-primaryColor gap-y-3 font-semibold`}
    >
      <NavLink
        // className="flex items-center text-gray-400 gap-x-4 hover:bg-slate-100 rounded-full px-4 py-2"
        className={`flex items-center text-gray-400 gap-x-4 hover:bg-amber-500 rounded-full px-4 py-2 ${
          isActive("/dashboard") || isActive("/dashboard-today")
            ? "text-white "
            : ""
        }`}
        to="/dashboard"
      >
        <RiDashboardFill
          size={25}
          className={` ${isActive("/dashboard") ? "text-white" : ""} `}
          color=""
        />
        <p className=" ">Dashboard</p>
      </NavLink>
      <NavLink
        className={`flex items-center text-gray-400 gap-x-4 hover:bg-amber-500 rounded-full px-4 py-2 ${
          isActive("/transaction") ? "text-white " : ""
        }`}
        to="/transaction"
      >
        <PiCoinsBold
          size={25}
          className={` ${isActive("/transaction") ? "text-white" : ""}`}
        />
        <p className=" ">Transaction</p>
      </NavLink>
      <NavLink
        className={`flex items-center text-gray-400 gap-x-4 hover:bg-amber-500 rounded-full px-4 py-2 ${
          isActive("/deposit_request") ? "text-white " : ""
        }`}
        to="/deposit_request"
      >
        <HiBanknotes
          size={25}
          className={` ${
            isActive("/deposit_request") ? "text-primaryColor" : ""
          }`}
        />
        <p className=" ">Deposit Request</p>
      </NavLink>

      <div className="divider my-1 px-4" />
      <p className="px-4 -mt-2 mb-2 text-xs">ACCOUNT</p>
      <NavLink
        to="/change_password"
        className={`flex items-center text-gray-400 gap-x-4 hover:bg-amber-500 rounded-full px-4 py-2 ${
          isActive("/change_password") ? "text-gray-800 " : ""
        }`}
      >
        <TbPasswordFingerprint
          size={25}
          className={` ${
            isActive("/change_password") ? "text-primaryColor" : ""
          }`}
        />
        <p className=" ">Change Password</p>
      </NavLink>
      <button
        onClick={(e) => {
          logout();
        }}
        className="flex items-center text-gray-400 gap-x-4 hover:bg-amber-500 rounded-full px-4 py-2"
      >
        <ArrowLeftEndOnRectangleIcon className="w-6" />
        <p className=" ">Log Out</p>
      </button>
    </div>
  );
}
