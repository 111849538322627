import React from "react";
import KeyFeatures from "./KeyFeatures";
import WhyUs from "./WhyUs";
import Footer from "./Footer";

function Home(props) {
  return (
    <div className="relative bg-white font-poppins pb-12 h-screen ">
      <div className="flex flex-col w-full">
        {/* header */}

        <div className="flex items-center w-full mt-24 max-w-7xl  mx-auto">
          {/* left side */}
          <div className="w-5/12">
            <h1 className="text-[48px] font-[900] text-primaryColor tracking-tight">
              Produk Digital Murah, Cepat, Dengan Kualitas Terbaik
            </h1>

            <h1 className="text-[18px] font-[900] mt-4 text-gray-600 tracking-normal">
              Dapatkan berbagai macam produk Pulsa, PPOB, Paket Data, Voucher
              Games, dan produk-produk lainnya dengan layanan terbaik dan
              kualitas yang memuaskan
            </h1>

            <button className="mt-12 px-16 py-4 text-white bg-amber-500 rounded-full">
              Ayo Gabung
            </button>
          </div>

          <div className="w-7/12">
            <img
              src="/homepage/header.jpg"
              className="w-full"
              alt="header.svg"
            />
          </div>
        </div>
        <WhyUs />
        {/* key features */}
        <KeyFeatures />

        {/* <div class="bg-white py-24 sm:py-32">
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 class="text-center text-lg font-semibold leading-8 text-gray-900">
              Trusted by the world’s most innovative teams
            </h2>
            <div class="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
              <img
                class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg"
                alt="Transistor"
                width="158"
                height="48"
              />
              <img
                class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg"
                alt="Reform"
                width="158"
                height="48"
              />
              <img
                class="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg"
                alt="Tuple"
                width="158"
                height="48"
              />
              <img
                class="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg"
                alt="SavvyCal"
                width="158"
                height="48"
              />
              <img
                class="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg"
                alt="Statamic"
                width="158"
                height="48"
              />
            </div>
          </div>
        </div> */}

        <Footer />
      </div>
    </div>
  );
}

export default Home;
