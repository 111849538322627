import Chart from "react-apexcharts";
import { numberFormatWithoutCurr } from "../../../Utils";
import NoData from "../../../component/NoData";
import { useSelector } from "react-redux";
export default function TopTransactionTime({ data }) {
  const loading = useSelector((state) => state.dashboard.loading);

  const dataSortByTime = data.slice().sort((a, b) => {
    return a.on_hour - b.on_hour;
  });

  const options = {
    series: [
      {
        name: "Revenue",
        data: dataSortByTime.map(
          (item) => `${numberFormatWithoutCurr(item.revenue)}`
        ),
      },
    ],
    options: {
      colors: ["#2c3364"],
      chart: {
        height: 350,
        type: "bar",
        foreColor: "#373d3f",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 5,
          borderRadiusApplication: "around",
          borderRadiusWhenStacked: "last",
          columnWidth: "50%",
          barHeight: "50%",
          distributed: false,
          rangeBarOverlap: true,
          rangeBarGroupRows: false,
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          dumbbellColors: undefined,
          isFunnel: false,
          isFunnel3d: true,
          colors: {
            ranges: [
              {
                from: 0,
                to: 0,
                color: undefined,
              },
            ],
            backgroundBarColors: [],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 0,
          },
          dataLabels: {
            position: "top",
            maxItems: 100,
            hideOverflowingLabels: true,
            orientation: "horizontal",
            total: {
              enabled: false,
              formatter: undefined,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontFamily: undefined,
                fontWeight: 600,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 0,
      },
      grid: {
        row: {
          colors: ["#f3f4f6"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: dataSortByTime.map((item) => {
          if (item.on_hour < 10) {
            return `0${item.on_hour}:00`;
          } else {
            return `${item.on_hour}:00`;
          }
        }),
        tickPlacement: "on",
      },
      yaxis: {
        title: {
          text: "High Transactions per hours",
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100],
        },
      },
    },
  };

  const loadingState = () => {
    return (
      <div className="flex flex-col gap-y-4">
        {[1, 2, 3].map((item, index) => {
          return (
            <tr key={index}>
              <div className="animate-pulse h-[35px] mx-auto rounded-lg bg-gray-300 items-center justify-between w-full" />
            </tr>
          );
        })}
      </div>
    );
  };

  if (loading) {
    return loadingState();
  } else if (!data || data.length === 0) {
    return (
      <div className="items-center">
        <NoData />
      </div>
    );
  } else {
    return (
      <Chart
        options={options.options}
        series={options.series}
        type="bar"
        height={250}
        width="100%"
      />
    );
  }
}
