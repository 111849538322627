import { UserCircleIcon } from "@heroicons/react/24/outline";
import {
  ArrowLeftEndOnRectangleIcon,
  ChartPieIcon,
} from "@heroicons/react/24/solid";
import React from "react";
import ApprovalList from "./components/ApprovalList";
import useLogout from "../../hooks/useLogout";

export default function Approval() {
  const { logout } = useLogout();

  return (
    <div className="relative bg-gray-100 font-poppins pb-12 h-screen">
      <div className="bg-primaryColor w-full h-[125px]"></div>

      <div className="bg-transparent flex w-full gap-x-4 -mt-20  px-8">
        <div className="flex flex-col p-6 rounded-lg bg-white w-[250px] h-fit gap-y-8 z-30 sticky top-[85px] ">
          <div className="flex items-center text-primaryColor gap-x-4">
            <ChartPieIcon className="w-6" />
            <p className=" ">Approval List</p>
          </div>

          <div className="h-[1px] rounded-full w-full bg-primaryColor"></div>

          <div className="flex items-center text-primaryColor gap-x-4">
            <UserCircleIcon className="w-7" />
            <p className=" ">Account</p>
          </div>

          <div
            onClick={(e) => {
              logout();
            }}
            className="flex items-center text-primaryColor gap-x-4 hover:cursor-pointer"
          >
            <ArrowLeftEndOnRectangleIcon className="w-6" />
            <p className=" ">Log Out</p>
          </div>
        </div>

        <div className="flex flex-col flex-grow h-[1600px] h-fit gap-y-4">
          <div className="flex gap-x-4 w-full">
            <ApprovalList />
          </div>
        </div>
      </div>
    </div>
  );
}
