import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axiosRequest from "../api/AxiosRequest";
import { exportToExcel } from "../Utils";
import { format } from "date-fns";

const useGetTransaction = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [idNumber, setIdNumber] = useState("");
  const [status, setStatus] = useState("all");
  const [value, setValue] = useState({
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  });
  const [currentPage, setCurrentPage] = useState(1);

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
  };
  const handleValueChange = (newValue) => {
    setValue(newValue);
  };

  // Invoke when user click to request another page.
  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const firstPage = () => {
    setCurrentPage(1);
  };
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getTransactionApi = async (isExport) => {
    if (isExport === 0) setLoading(true);
    else setExportLoading(true);

    const param = {
      status: status === "all" ? "" : status,
      start_date: value.startDate || "",
      end_date: value.endDate || "",
      identifier_number: idNumber,
      limit: 10,
      page: currentPage,
    };

    const url = `/api/v1/transactions?status=${param.status}&identifier_number=${param.identifier_number}&start_date=${param.start_date}&end_date=${param.end_date}&limit=${param.limit}&page=${param.page}&export=${isExport}`;

    try {
      const response = await axiosRequest(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("jwt"),
        },
      });
      if (isExport === 0) setTransactions(response.data.data);
      else exportToExcel(response.data.data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      if (isExport === 0) setLoading(false);
      else setExportLoading(false);
    }
  };

  useEffect(() => {
    getTransactionApi(0);
  }, [status, value, currentPage]);

  return {
    transactions,
    loading,
    value,
    handleValueChange,
    getTransactionApi,
    handleStatusChange,
    idNumber,
    setIdNumber,
    nextPage,
    prevPage,
    firstPage,
    currentPage,
    exportLoading,
  };
};

export default useGetTransaction;
