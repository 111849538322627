import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const numberFormatWithoutCurr = (value) =>
  new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "IDR",
    maximumSignificantDigits: 20,
  })
    .format(value)
    .replace("IDR", "")
    // .replace(",", ".")
    .trim();

export const formatNumber = (num) => {
  if (Math.abs(num) >= 1e9) {
    return (num / 1e9).toFixed(2) + "B";
  }
  if (Math.abs(num) >= 1e6) {
    return (num / 1e6).toFixed(2) + "M";
  }
  if (Math.abs(num) >= 1e3) {
    return (num / 1e3).toFixed(2) + "K";
  }
  return num;
};

export const getIdRegex = () => {
  return new RegExp("^\\d{16}$");
};

export const getNpwpRegex = () => {
  return /^[0][1-9][.]([\d]{3})[.]([\d]{3})[.][\d][-]([\d]{3})[.]([\d]{3})$/g;
};

export const getEmailRegex = () => {
  const flags = "gm";
  const pattern = "[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}";
  return new RegExp(pattern, flags);
};

export const getPhoneNoRegex = () => {
  return /^(\+62|62|0)8[1-9][0-9]{6,9}$/;
};

export const getIpAddressRegex = () => {
  return /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/;
};

export const exportToExcel = (data) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Buffer to store the generated Excel file
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });

  saveAs(blob, "data.xlsx");
};

export const generateMonthArray = () => {
  const months = [
    { month: "Jan", month_value: "01", fullName: "January" },
    { month: "Feb", month_value: "02", fullName: "February" },
    { month: "Mar", month_value: "03", fullName: "March" },
    { month: "Apr", month_value: "04", fullName: "April" },
    { month: "May", month_value: "05", fullName: "May" },
    { month: "Jun", month_value: "06", fullName: "Juny" },
    { month: "Jul", month_value: "07", fullName: "July" },
    { month: "Aug", month_value: "08", fullName: "August" },
    { month: "Sep", month_value: "09", fullName: "September" },
    { month: "Oct", month_value: "10", fullName: "October" },
    { month: "Nov", month_value: "11", fullName: "November" },
    { month: "Dec", month_value: "12", fullName: "December" },
  ];

  return months;
};

export const dummyData = () => {
  const arrayData = new Array(5).fill().map((index) => ({
    id: index,
  }));

  return arrayData;
};

export const isAllowed = (allowedRoles, user) => {
  return allowedRoles.includes(user?.role_id);
};

export const getDate = () => {
  const month = (new Date().getMonth() + 1).toString().padStart(2, "0");
  const year = new Date().getFullYear();

  return `${year}-${month}`;
};

export const getComparisonMonth = () => {
  return generateMonthArray()[new Date().getMonth() - 1].fullName;
};
