import { UserCircleIcon } from "@heroicons/react/16/solid";
import React, { useState } from "react";
import { useUser } from "../context/UserContext";
import { numberFormatWithoutCurr } from "../Utils";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid";
import { NavLink, useLocation } from "react-router-dom";

export default function Navbar() {
  const { user, balance } = useUser();

  const isAllowed = (allowedRoles) => {
    return allowedRoles.includes(user?.role_id);
  };

  const [collapsedNavbar, setCollapsedNavbar] = useState(false);

  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <nav>
      <div className="bg-white z-50 sticky top-0 border-b border-gray-200 py-4 px-8 md:px-12 justify-between flex font-poppins">
        <button
          className="md:hidden mr-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
          onClick={() => {
            setCollapsedNavbar((prev) => {
              return !prev;
            });
          }}
        >
          {collapsedNavbar ? (
            <XMarkIcon width={24} />
          ) : (
            <Bars3Icon width={24} />
          )}
        </button>
        <img className="w-[80px] my-auto" src="./logo.jpeg" />

        {/* <a href="/">
          <p>HARGA</p>
        </a> */}
        <div className="hidden md:flex items-center flex-grow justify-center  text-primaryColor font-[600]">
          <a className="group flex items-center flex-col px-8 " href="/">
            <p className="group-hover:text-amber-500 duration-300">Home</p>
            <div className="group-hover:h-1 h-0 duration-300 w-0 mt-1 group-hover:w-full bg-amber-500 rounded-full "></div>
          </a>

          <a
            className="group flex items-center flex-col px-8 "
            href="/integration"
          >
            <p className="group-hover:text-amber-500 duration-300">Integrasi</p>
            <div className="group-hover:h-1 h-0 duration-300 w-0 mt-1 group-hover:w-full bg-amber-500 rounded-full "></div>
          </a>

          {!user && (
            <a
              className="group flex items-center flex-col px-8 "
              href="/pendaftaran"
            >
              <p className="group-hover:text-amber-500 duration-300">
                Pendaftaran
              </p>
              <div className="group-hover:h-1 h-0 duration-300 w-0 mt-1 group-hover:w-full bg-amber-500 rounded-full "></div>
            </a>
          )}

          {user && isAllowed([1]) && (
            <a
              className="group flex items-center flex-col px-8"
              href="/approval"
            >
              <p className="group-hover:text-amber-500 duration-300">
                Approval
              </p>
              <div className="group-hover:h-1 h-0 duration-300 w-0 mt-1 group-hover:w-full bg-amber-500 rounded-full "></div>
            </a>
          )}

          {user && isAllowed([1, 2]) && (
            <a
              className="group flex items-center flex-col px-8"
              href="/dashboard"
            >
              <p className="group-hover:text-amber-500 duration-300">
                Dashboard
              </p>
              <div className="group-hover:h-1 h-0 duration-300 w-0 mt-1 group-hover:w-full bg-amber-500 rounded-full "></div>
            </a>
          )}
        </div>

        {user ? (
          <div className="flex items-center gap-x-4 text-primaryColor">
            <div className="flex flex-col items-end">
              <h2>{user.username}</h2>
              <h2 className="font-bold">
                Rp. {numberFormatWithoutCurr(parseInt(balance))}
              </h2>
            </div>

            <UserCircleIcon className="w-8  " />
          </div>
        ) : (
          <>
            <a
              className="bg-primaryColor flex items-center px-8 py-3 rounded-full text-white text-sm"
              href="/login"
            >
              <p>Masuk</p>
            </a>
          </>
        )}
      </div>

      <div className={`${collapsedNavbar ? "" : "hidden"} bg-primaryColor`}>
        <div className="space-y-1 px-2 pb-3 pt-2 flex flex-col items-start flex-grow  text-white font-[600]">
          <NavLink
            className={`group flex items-center flex-col px-8 ${
              isActive("/") ? "text-amber-500 " : ""
            }`}
            to="/"
          >
            <p className="group-hover:text-amber-500 duration-300">Home</p>
            <div className="group-hover:h-1 h-0 duration-300 w-0 mt-1 group-hover:w-full bg-amber-500 rounded-full "></div>
          </NavLink>

          <NavLink
            className={`group flex items-center flex-col px-8 ${
              isActive("/integration") ? "text-amber-500 " : ""
            }`}
            to="/integration"
          >
            <p className="group-hover:text-amber-500 duration-300">Integrasi</p>
            <div className="group-hover:h-1 h-0 duration-300 w-0 mt-1 group-hover:w-full bg-amber-500 rounded-full "></div>
          </NavLink>

          {!user && (
            <NavLink
              className={`group flex items-center flex-col px-8 ${
                isActive("/pendaftaran") ? "text-amber-500 " : ""
              }`}
              to="/pendaftaran"
            >
              <p className="group-hover:text-amber-500 duration-300">
                Pendaftaran
              </p>
              <div className="group-hover:h-1 h-0 duration-300 w-0 mt-1 group-hover:w-full bg-amber-500 rounded-full "></div>
            </NavLink>
          )}

          {user && isAllowed([1]) && (
            <NavLink
              className={`group flex items-center flex-col px-8 ${
                isActive("/approval") ? "text-amber-500 " : ""
              }`}
              to="/approval"
            >
              <p className="group-hover:text-amber-500 duration-300">
                Approval
              </p>
              <div className="group-hover:h-1 h-0 duration-300 w-0 mt-1 group-hover:w-full bg-amber-500 rounded-full "></div>
            </NavLink>
          )}

          {user && isAllowed([1, 2]) && (
            <NavLink
              className={`group flex items-center flex-col px-8 ${
                isActive("/dashboard") || isActive("/dashboard-today")
                  ? "text-amber-500 "
                  : ""
              }`}
              to="/dashboard"
            >
              <p className="group-hover:text-amber-500 duration-300">
                Dashboard
              </p>
              <div className="group-hover:h-1 h-0 duration-300 w-0 mt-1 group-hover:w-full bg-amber-500 rounded-full "></div>
            </NavLink>
          )}
        </div>
      </div>
    </nav>
  );
}
