import { useEffect, useState } from "react";
import axiosRequest from "../api/AxiosRequest";

const useGetApprovalList = () => {
  const [approvalList, setApprovalList] = useState([]);
  const [approvalListFiltered, setApprovalListFiltered] = useState([]);

  const filterListByStatus = (status) => {
    if (status === "all") {
      setApprovalListFiltered([...approvalList]);
    } else {
      const temp = [...approvalList];
      const filtered = temp.filter((item) => item.attributes.status === status);
      setApprovalListFiltered(filtered);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axiosRequest.get("/api/v1/resellers", {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("jwt"),
        },
      });

      setApprovalList(response.data.data);
      setApprovalListFiltered(response.data.data);
    };

    fetchData();
  }, []);

  return { approvalList, approvalListFiltered, filterListByStatus };
};

export default useGetApprovalList;
