// axiosInstance.js
import axios from "axios";
import toast from "react-hot-toast";

// Create an instance of Axios
const axiosRequest = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Replace with your API base URL
  //   timeout: 10000, // Request timeout (optional)
  //   withCredentials: true,
});

// Request interceptor
axiosRequest.interceptors.request.use(
  (config) => {
    // const token = localStorage.getItem("token"); // Get token from local storage
    // if (token) {

    // }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response interceptor
axiosRequest.interceptors.response.use(
  (response) => {
    // Modify response data or handle it

    return response;
  },
  (error) => {
    if (!error) {
      return Promise.reject(new Error("Terjadi kesalahan"));
    } else {
      //  Handle response error
      if (error.response) {
        // Server responded with a status other than 2xx
        if (error.response.status === 401) {
          // Handle unauthorized error, e.g., redirect to login
          console.log("Unauthorized, redirecting to login...");
          localStorage.clear();
          window.location.href = "/login";
        } else if (error.response.status === 500) {
          // Handle server error
          console.log("Server error, please try again later.");
        }
        // toast.error(error.response.status.message);
      } else if (error.request) {
        // Request was made but no response was received
        console.log(error);
      } else {
        // Something happened in setting up the request
        console.log("Error", error.message);
        toast.error(error.message);
      }
      return Promise.reject(error);
    }
  }
);

export default axiosRequest;
